<template>
  <overall-settings :fields="fields">
    <form-layout class="c-login__layout" :fields="fields?.layout?.fields">
      <div class="c-login__logo">
        <a :href="`/${page.itemLanguage}`">
          <icon :field="fields.logo" />
        </a>
      </div>
      <div class="c-login">
        <div class="c-login__section" v-show="step === 1">
          <button class="c-login__back" @click="goBack" v-if="!$isNullOrEmpty(fields.backText?.value)">
            <icon name="back" />
            <jss-text class="c-login__back-text" :field="fields.backText" />
          </button>
          <div class="c-login__behaviors" :class="[{ swapped: fields.swapLoginCreate?.value }]">
            <div class="c-login__form">
              <jss-rich-text class="c-login__section-title" :field="ifEmail(fields.verificationEmailTitle, fields.verificationMobileTitle)" />
              <jss-rich-text class="c-login__section-body" :field="ifEmail(fields.verificationEmailBody, fields.verificationMobileBody)" tag="div" />
              <dynamic-form :form="fields?.verificationForm" v-bind:data="verificationFormCfg" ref="verificationFormRef" />
              <site-button :fields="fields?.sendButton?.fields" @click="onSendCode" :disabled="sendCodeDisabled" />
              <div class="c-login__links">
                <jss-rich-text :field="fields.emailLoginText" v-if="accountType === 'mobile'" />
                <jss-rich-text :field="fields.mobileLoginText" v-if="accountType === 'email'" />
              </div>
            </div>
            <div class="c-login__line" />
            <div class="c-login__create-account" v-if="!$isEmptyFields([fields.createAccountTitle, fields.createAccountBody]) || fields.createAccountButton">
              <jss-rich-text class="mg-b-16" :field="fields.createAccountTitle" tag="div" />
              <jss-rich-text class="mg-b-32" :field="fields.createAccountBody" tag="div" />
              <site-button v-bind="fields.createAccountButton" />
            </div>
          </div>
        </div>
        <div class="c-login__section" v-show="step === 2">
          <button class="c-login__back" @click="goBack" v-if="!$isNullOrEmpty(fields.backSignInText?.value)">
            <icon name="back" />
            <jss-text class="c-login__back-text" :field="fields.backSignInText" />
          </button>
          <jss-rich-text class="c-login__section-title" :field="ifEmail(fields.checkEmailTitle, fields.checkMobileTitle)" />
          <jss-rich-text class="c-login__section-subtitle" :field="ifEmail(fields.checkEmailSubtitle, fields.checkMobileSubtitle)" tag="div" />
          <div
            class="c-login__section-body"
            v-html="$formatString(ifEmailText(fields.checkEmailBody, fields.checkMobileBody), { account: accountType === 'mobile' ? `+${account?.area?.code}${account?.number}` : account, seconds })"
          ></div>
          <captcha-input class="c-login__captcha" v-model:value="code" :description="fields.captchaInput?.fields?.description?.value" ref="captchaInputRef" />
          <site-button :fields="verifyButtonFields" @click="onVerify" :disabled="submitting" />
          <div class="c-login__resend-code __ft-ps" v-if="captchaHasSent && seconds === 0">
            <span>{{ fields.resendText1?.value }}</span>
            &nbsp;
            <span class="c-login__resend-code-btn" @click="onSendCode"> {{ fields.resendText2?.value }}</span>
          </div>
        </div>
        <div class="c-login__line" />
        <div class="c-login__help">
          <jss-rich-text class="c-login__help-title" :field="fields.helpTitle" />
          <jss-rich-text class="c-login__help-body" :field="fields.helpBody" />
          <div class="c-login__help-links">
            <div class="c-login__help-link" v-for="helpLink in fields.helpLinks" :key="helpLink.id">
              <site-link :link="helpLink.fields.link">
                <icon :field="helpLink.fields.icon" />
                <jss-text class="c-login__help-link-text" :field="helpLink.fields.text" tag="span" />
              </site-link>
            </div>
          </div>
        </div>
      </div>
    </form-layout>
  </overall-settings>
  <gee-captcha captcha-name="web-login" ref="geeCaptchaRef" />
  <modal class="c-login__set-password" ref="setAccountModalRef">
    <jss-rich-text class="c-login__set-password-title" :field="fields?.setAccountTitle" tag="div" />
    <jss-rich-text class="c-login__set-password-subtitle" :field="fields?.setAccountSubtitle" tag="div" />
    <dynamic-form :form="fields?.setAccountForm" ref="setAccountFormRef" />
    <div class="c-login__set-password-buttons">
      <site-button :fields="fields?.setAccountSubmitButton?.fields" @click="onSetAccount" />
      <site-button :fields="fields?.setAccountSkipButton?.fields" @click="onSkipSetAccount" />
    </div>
  </modal>
</template>

<script>
/**
 * @typedef LoginFields
 * @property {DropLinkField<FormLayoutFields>} layout
 * @property {ImageField} logo
 * @property {SimpleField} backLotusText
 * @property {GlobalSettingEntry} verificationTitleType
 * @property {SimpleField} verificationEmailTitle
 * @property {SimpleField} verificationMobileTitle
 * @property {SimpleField} verificationEmailBody
 * @property {SimpleField} verificationMobileBody
 * @property {Form} verificationForm
 * @property {ButtonField} emailLoginButton
 * @property {ButtonField} mobileLoginButton
 * @property {ButtonField} createAccountButton
 * @property {ButtonField} sendButton
 * @property {SimpleField} backText
 * @property {SimpleField} checkEmailTitle
 * @property {SimpleField} checkMobileTitle
 * @property {SimpleField} checkEmailSubtitle
 * @property {SimpleField} checkMobileSubtitle
 * @property {SimpleField} checkEmailBody
 * @property {SimpleField} checkMobileBody
 * @property {DropLinkField<CaptchaInputFields>} captchaInput
 * @property {ButtonField} verifyButton
 * @property {SimpleField} loadingText
 * @property {SimpleField} resendText1
 * @property {SimpleField} resendText2
 * @property {SimpleField} helpTitle
 * @property {SimpleField} helpBody
 * @property {Array<LoginHelpLink>} helpLinks
 * @property {CheckField} swapLoginCreate
 * @property {Form} setAccountForm
 * @property {SimpleField} setAccountTitle
 * @property {SimpleField} setAccountSubtitle
 * @property {ButtonField} setAccountSubmitButton
 * @property {ButtonField} setAccountSkipButton
 * @property {SimpleField} unmatchedPasswordMessage
 * @property {SimpleField} loginSuccessMessage
 * */
/**
 * @typedef LoginHelpLink
 * @property {{
 *   icon: IconField,
 *   text: SimpleField,
 *   link: LinkField
 * }} fields
 * */
import DynamicForm from '@/sections/dynamic-form';
import { reactive, toRefs, inject, computed, onMounted, watch } from 'vue';
import { debounce, merge } from 'lodash';
import api from '@/api';
import { accountTypes, CAPTCHA_SCENE, captchaTypes, ERROR_CODES, loginTypes } from '@/utils/constants';
import useAppStore from '@/store/appStore';
import { useI18n } from 'vue-i18n';
import { getGlobalConfigs } from '@/utils/site-utils';
import { getBetterUrl, getQueryStrings, goBack, isAbsoluteUri } from '@/utils/uri-utils';
import { isNullOrEmpty, isNullOrWhitespace } from '@/utils/obj-utils';
import { canUseDOM } from '@/utils/dom-utils';
import { getEncryptor } from '@/services/siteService';
import { GET_CAPTCHA_SCENE, getCaptchaExecutor } from '@/utils/captcha-utils';
import { equalString, ifEmpty } from '@/utils/string-utils';
import { useRoute, useRouter } from 'vue-router';
import { gtmLogin } from '@/utils/gtm-utils';
export default {
  name: 'Login',
  props: {
    /**@type LoginFields*/
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props, ctx) {
    if (!props.fields) return;
    const { deviceState } = inject('device-common');
    if (!props.fields) return;
    /**@type AppStore*/
    const appStore = useAppStore();
    const toast = inject('toast');
    const loading = inject('loading');
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    let codeTimeout = null;
    let getCaptcha, doLogin;
    const state = reactive({
      accountType: 'email',
      step: 1,
      /**@type DynamicForm*/
      verificationFormRef: null,
      verificationFormCfg: {
        email: {
          visible: false
        },
        mobile: {
          visible: false
        }
      },
      account: null,
      code: null,
      seconds: 0,
      captchaInputRef: null,
      captchaVerified: false,
      captchaHasSent: false,
      /**@type ModalRef*/
      setAccountModalRef: null,
      /**@type DynamicForm*/
      setAccountFormRef: null,
      /**@type GeeCaptchaRef*/
      geeCaptchaRef: null,
      redirectUrl: null,
      sendCodeDisabled: true,
      submitting: false
    });
    const computes = {
      submitEnabled: computed(() => {
        return state.captchaHasSent;
      }),
      verifyButtonFields: computed(() => {
        if (!props.fields.verifyButton?.fields) return null;
        const { text, ...otherFields } = props.fields.verifyButton?.fields || {};
        return {
          ...otherFields,
          text: {
            value: state.submitting ? props.fields.loadingText?.value ?? 'LOADING' : text.value
          }
        };
      })
    };
    const _methods = {
      async encrypt(password) {
        const encryptor = getEncryptor();
        const [res, ex] = await encryptor.encrypt(password);
        if (ex) {
          await toast.showEx(ex);
          return null;
        }
        return res;
      },
      startCodeCountdown(seconds) {
        const fn = (seconds) => {
          state.seconds = seconds;
          if (seconds > 0) {
            codeTimeout = setTimeout(() => {
              fn(--seconds);
            }, 1000);
          }
        };
        fn(seconds);
      },
      setRedirectUrl() {
        let [redirectUrl] = getQueryStrings('redirect_url');
        if (isNullOrWhitespace(redirectUrl)) {
          const [myOrderLink] = getGlobalConfigs(props.page, 'myOrderLink');
          redirectUrl = getBetterUrl(ifEmpty(myOrderLink?.value?.href, '/my-lotus'), props.page.itemLanguage, true, false);
        }
        if (!isAbsoluteUri(redirectUrl)) {
          redirectUrl = getBetterUrl(redirectUrl, props.page.itemLanguage, true, false);
        }
        state.redirectUrl = redirectUrl;
      },
      async alertThenRedirect() {
        window.location = state.redirectUrl;
      },
      checkAccountType() {
        if (equalString(global.location.hash, '#mobile')) {
          state.accountType = 'mobile';
        }
        if (equalString(global.location.hash, '#email')) {
          state.accountType = 'email';
        }
      },
      onPopstate: debounce(() => {
        _methods.checkAccountType();
      }, 200)
    };
    const methods = {
      async onSendCode() {
        const [valid, account] = state.verificationFormRef.validateItemByName(state.accountType, true);
        state.account = account;
        if (!valid) return;
        loading.show();
        let geeResult = await state.geeCaptchaRef.validate();
        console.log('geeResult', geeResult);
        const body = merge(
          {
            captchaType: captchaTypes[state.accountType],
            captchaScene: CAPTCHA_SCENE.login
          },
          geeResult
        );
        if (state.accountType === 'mobile') {
          merge(body, {
            mobileAreaCode: account?.area?.code,
            phone: account?.number
          });
        } else {
          state.account = account;
          merge(body, {
            email: account
          });
        }
        const [res, ex] = await getCaptcha(null, body);
        if (ex) {
          await toast.showEx(ex);
          loading.hide();
          return;
        }
        loading.hide();
        _methods.startCodeCountdown(60);
        state.captchaHasSent = true;
        state.step = 2;
      },
      setAccountType(type) {
        state.accountType = type;
      },
      ifEmail(emailField, mobileField) {
        const field = (state.accountType === 'email' ? emailField : mobileField) ?? emailField;
        return isNullOrEmpty(field?.value) ? emailField : field;
      },
      ifEmailText(emailField, mobileField) {
        const field = methods.ifEmail(emailField, mobileField);
        return ifEmpty(field?.value, emailField?.value);
      },
      goBack() {
        if (state.step === 2) {
          state.step = 1;
        } else {
          goBack(router);
        }
      },
      async onVerify() {
        const errorMsg = props.fields.captchaInput?.fields?.errorMsg?.value ?? 'The code you entered was incorrect or incomplete';
        const successMsg = props.fields.captchaInput?.fields?.successMsg?.value ?? 'Verification successful';
        if (isNullOrWhitespace(state.code) || state.code.length < 6) {
          state.captchaInputRef.setErrorMsg(errorMsg);
        }
        if (codeTimeout) {
          clearTimeout(codeTimeout);
        }

        const bodyLogin = {
          accountType: accountTypes[state.accountType],
          registerSource: '202'
        };
        if (state.accountType === 'mobile') {
          merge(bodyLogin, {
            account: state.account?.number,
            mobileAreaCode: state.account?.area?.code
          });
        } else {
          merge(bodyLogin, {
            account: state.account
          });
        }
        merge(bodyLogin, { code: state.code, loginType: loginTypes[`${state.accountType}JustLogin`] });
        state.submitting = true;
        const [resLogin, exLogin] = await doLogin(null, bodyLogin);
        if (exLogin) {
          loading.hide();
          await toast.showEx(exLogin);
          state.submitting = false;
          return;
        }
        if (isNullOrEmpty(resLogin?.token)) {
          loading.hide();
          if (resLogin?.crossBorderFlag) {
            await toast.showEx({ code: ERROR_CODES.CrossBorderError });
          } else {
            await toast.showEx({ code: ERROR_CODES.WrongAccountOrPassword });
          }
          state.submitting = false;
          return;
        }
        state.captchaVerified = true;
        state.captchaInputRef.setSuccessMsg(successMsg);
        await appStore.setLoginInfo(resLogin);
        gtmLogin(resLogin);
        // if (res.needCompleteInfo) {
        //   await state.setAccountModalRef.open();
        //   return;
        // }
        setTimeout(() => {
          state.submitting = false;
          _methods.alertThenRedirect();
        }, 2000);
      },
      async onSetAccount() {
        const [valid, setAccountFormData] = state.setAccountFormRef.validate();
        if (!valid) return;
        const { username, password } = setAccountFormData;
        loading.show();
        const encryptedPassword = await _methods.encrypt(password);
        const [res, ex] = await api.cidp.completeUserInfo(null, {
          username: username,
          password: encryptedPassword
        });
        if (ex) {
          await toast.showEx(ex);
          loading.hide();
        }
      },
      onSkipSetAccount() {
        state.setAccountModalRef.close();
        _methods.alertThenRedirect();
      }
    };
    watch(
      () => state.accountType,
      (accountType) => {
        const { email, mobile } = state.verificationFormCfg;
        email.visible = accountType === 'email';
        mobile.visible = accountType === 'mobile';
      },
      {
        immediate: true
      }
    );
    if (canUseDOM()) {
      loading.show();
    }
    onMounted(async () => {
      loading.show();
      let [accountType] = getQueryStrings('account_type');
      if (!['mobile', 'email'].includes(accountType)) accountType = 'email';
      state.accountType = accountType;
      _methods.setRedirectUrl();
      if (appStore.loginInfo?.lotusId) {
        window.location = state.redirectUrl;
        return;
      }
      [getCaptcha, doLogin] = getCaptchaExecutor(GET_CAPTCHA_SCENE.LOGIN);
      await state.geeCaptchaRef.init();
      state.sendCodeDisabled = false;
      loading.hide();
    });
    return {
      ...toRefs(state),
      deviceState,
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-login {
  $c: &;
  $m: #{'.e-modal'};
  $l: #{'.s-form-layout'};
  &__layout {
    #{$l}__side-image {
      display: block;
    }
  }
  .s-dynamic-form {
    padding: 0;
    position: relative;
    z-index: 2;
    + .e-site-button {
      margin-top: -24px;
    }
  }
  .e-site-button {
    width: 100%;
  }
  &__logo {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $black;
    color: $white;
    .e-icon {
      position: relative;
      top: 2px;
      svg {
        width: 128px;
        height: auto;
      }
    }
  }
  &__behaviors {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 16px;
    &.swapped {
      #{$c}__form {
        order: 3;
      }
      #{$c}__line {
        order: 2;
      }
      #{$c}__create-account {
        order: 1;
      }
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .s-dynamic-form {
      margin-top: 16px;
    }
  }
  &__section {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__back {
    margin-top: 40px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border-color: transparent;
    gap: 8px;
    color: $grey-night;
    cursor: pointer;
    .e-icon {
      svg {
        width: 16px;
        height: auto;
      }
    }
    &-text {
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;

    .e-site-button {
      &.primary-link {
        height: auto;
        justify-content: flex-start;
      }
    }
  }
  &__line {
    width: 100%;
    border-top: 1px solid $grey-89;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  &__create-account {
    padding-top: 24px;
  }
  &__resend-code {
    margin-top: 16px;
    &-btn {
      color: $black;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__help {
    padding: 16px 0 40px 0;
    &-title {
      margin-bottom: 8px;
    }
    &-links {
      margin-top: 24px;
    }
    &-link {
      .e-site-link {
        display: inline-flex;
        align-items: center;
        .e-icon {
          margin-right: 12px;
          svg {
            width: 24px;
          }
        }
      }
      &-text {
        font-weight: 700;
      }
    }
  }
  &__set-password {
    &.e-modal {
      align-items: flex-end;
      #{$m}__content {
        padding: 24px;
      }
    }
    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  @include tablet-landscape {
    padding: 0;
    &__layout {
      min-height: 100vh;
      #{$l}__main {
        @include grid-block(1, 12);
        padding-top: 40px;
      }
      #{$l}__side-image {
        height: 100vh;
      }
      #{$l}__image-wrapper {
        height: 100vh;
      }
      #{$l}__image {
        z-index: 1;
      }
    }
    &__logo {
      z-index: 1;
      width: auto;
      background: transparent;
      top: 28px;
      right: 48px;
      left: auto;
      display: flex;
      justify-content: flex-end;
      color: $white;
      svg {
        width: 128px;
        height: auto;
      }
    }
    &__section {
      padding-top: 40px;
      &-body {
        margin-bottom: 16px;
      }
    }
    &__action {
      margin-top: 50px;
      justify-content: flex-start;
      .e-site-button {
        + .e-site-button {
          margin-left: 15px;
        }
      }
    }
    &__set-password {
      &.e-modal {
        align-items: center;
        #{$m}__content {
          width: grid-width(8);
          padding: 40px;
        }
      }
    }
  }
}
</style>
