<template>
  <overall-settings v-bind="$props">
    <div class="c-locate-dealer">
      <background-image class="c-locate-dealer__bg" :image="$adaptiveImageField(fields.bgpc, fields.bgm)" position="top center">
        <div class="c-locate-dealer__content">
          <div class="c-locate-dealer__title">
            <AnimatedContent type="fade-in" :hide-content="false" :duration="1000" :delay="600">
              <JssRichText :field="$adaptiveTextField(fields.title, fields.titlem, fields.titlet)" />
            </AnimatedContent>
          </div>
          <div class="c-locate-dealer__button">
            <AnimatedContent type="bottom-fade-in" v-for="(btn, i) in fields.button" :key="btn.id" :delay="600 + 200 * i">
              <SiteButton v-bind="btn" />
            </AnimatedContent>
          </div>
        </div>
      </background-image>
    </div>
  </overall-settings>
</template>

<script>
/**@typedef LocateDealerFields */
export default {
  name: 'LocateDealer',
  props: {
    /**@type LocateDealerFields*/
    fields: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  setup(props) {}
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-locate-dealer {
  margin: 0 auto auto auto;
  background-color: #fff;
  height: 686px;
  &__bg {
    height: 100%;
    .e-background-image__img {
      background-position: top center;
    }
    .e-background-image__content {
      height: 100%;
    }
  }
  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
    gap: 64px;
    padding: 24px 24px 60px 24px;
  }
  &__button {
    align-self: stretch;
    .e-site-button {
      width: 100%;
    }
  }
  @include tablet {
    height: 100vw;
    background-color: $black;
    &__content {
      padding: grid-width-m(1);
    }
    &__button {
      .e-site-button {
        width: fit-content;
      }
    }
  }
  @include tablet-landscape {
    height: 48vw;
    margin: 0 auto auto auto;
    background-color: #fff;
    &__bg {
      .e-background-image__content {
        padding: 0 grid-width(2);
        display: flex;
        justify-content: flex-end;
      }
    }
    &__content {
      padding: 24px 0;
      justify-content: center;
    }
    &__button {
      align-self: flex-start;
      padding-left: 3px;
    }
  }
}
html.rtl {
  .c-locate-dealer {
    @include tablet-landscape {
      &__button {
        padding-left: 0;
        padding-right: 3px;
      }
    }
  }
}
</style>
