<template>
  <OverallSettings v-bind="$props">
    <FormLayout :fields="fields">
      <div class="c-logout">
        <div class="c-logout__back">
          <SiteButton v-bind="fields.backButton" />
        </div>
        <Heading class="c-logout__title" :field="fields.title" :type="fields.titleType" />
        <JssRichText class="c-logout__body" :field="fields.body" />
        <JssRichText class="c-logout__description" :field="fields.description" />
        <div class="c-logout__items">
          <JssRichText class="c-logout__item" v-for="item in fields.items" :key="item.id" :field="item.fields.text" />
        </div>
      </div>
    </FormLayout>
  </OverallSettings>
</template>
<script>
export default {
  name: 'Logout',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-logout {
  &__title {
    @include h6;
    margin-top: $space-64;
  }
  &__body {
    margin-top: $space-32;
  }
  &__description {
    margin-top: $space-80;
  }
  &__items {
    margin-top: $space-32;
  }
  &__item {
    margin-top: $space-24;
  }
}
</style>
