<template>
  <overall-settings v-bind="$props">
    <div class="c-market-selector">
      <adaptive-image :field="fields.image">
        <div class="c-market-selector__form">
          <heading class="c-market-selector__title" :field="fields.title" :type="fields.titleType" rich />
          <dynamic-form :form="fields.form" :data="formData" ref="formRef" @change="onFormChange" />
          <site-button class="c-market-selector__button" :fields="fields.button?.fields" @click="switchMarket" />
        </div>
      </adaptive-image>
    </div>
  </overall-settings>
</template>

<script>
/**
 * @typedef MarketSelector
 * @property {SimpleField} title
 * @property {GlobalSettingEntry} titleType
 * @property {Form} form
 * @property {Form} form
 * @property {Form} form
 * @property {SimpleField} languageLabel
 * */

import { computed, inject, onMounted, reactive, toRefs } from 'vue';
import { getCountries, getPageAlpha2Code, themeClass } from '@/utils/site-utils';
import { equalString } from '@/utils/string-utils';
import { gtmPush } from '@/utils/gtm-utils';
import api from '@/api';
import { getBetterUrl, isAbsoluteUri, redirectTo } from '@/utils/uri-utils';
import { useRouter } from 'vue-router';
import { isNullOrEmpty } from '@/utils/obj-utils';

export default {
  name: 'MarketSelector',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const router = useRouter();
    const toast = inject('toast');
    const countries = getCountries(props.page);
    const selectedCountry = countries.find((x) => x.code === getPageAlpha2Code(props.page));
    const selectedLanguage = selectedCountry?.languages.find((x) => equalString(x.code, props.page.itemLanguage));
    const state = reactive({
      matched: false,
      countries,
      preferCountry: selectedCountry,
      preferLanguage: selectedLanguage,
      countryErrorMsg: null,
      languageErrorMsg: null,
      /**@type DynamicForm*/
      formRef: null,
      formData: {
        country: {
          controlProps: {
            options: countries,
            selectedOption: selectedCountry
          }
        },
        language: {
          controlProps: {
            options: selectedCountry?.languages,
            selectedOption: selectedLanguage
          }
        }
      }
    });
    const computes = {
      mainClasses: computed(() => [themeClass(props, 'yellow')])
    };
    const methods = {
      onFormChange(itemName, itemValue) {
        switch (itemName) {
          case 'country':
            {
              if (itemValue?.data.languages?.length > 0) {
                const options = itemValue?.data.languages;
                state.formRef.updateItem('language', {
                  controlProps: {
                    options: options,
                    selectedOption: options[0]
                  }
                });
              } else {
                state.formRef.updateItem('language', {
                  controlProps: {
                    options: [],
                    selectedOption: null
                  }
                });
              }
            }
            break;
          default:
            break;
        }
      },
      async switchMarket() {
        const [valid, formData] = await state.formRef.validate();
        if (!valid) return;
        const { language } = formData;
        const { code, link } = language;
        const { origin, pathname, search, hash } = window.location;
        console.log('link', link);
        if (!isNullOrEmpty(link?.href)) {
          let url = getBetterUrl(link?.href, code, true);
          setTimeout(() => {
            window.location.href = url;
          }, 100);
          return;
        }
        if (isNullOrEmpty(code)) return;
        let newPath;
        const reg = /^\/(en|([a-z]{2}-[a-z]{2}))\//gi;
        const reg1 = /^\/(en|([a-z]{2}-[a-z]{2}))$/gi;
        if (reg.test(pathname)) {
          newPath = pathname.replace(reg, `/${code}/`);
        } else if (reg1.test(pathname)) {
          newPath = pathname.replace(reg1, `/${code}`);
        } else {
          newPath = `/${code}${pathname}`;
        }
        gtmPush({
          event: 'market_selector',
          event_label: code
        });
        setTimeout(() => {
          window.location.href = `${origin}${newPath}${search}${hash}`;
        }, 100);
      }
    };
    onMounted(async () => {
      const [res, ex] = await api.web.ipDetect();
      if (!res?.length) return;
      let matched = false;
      const visible = res.every((x) => !equalString(props.page.itemLanguage, x));
      if (visible) {
        for (let c of state.countries) {
          for (let l of c.data.languages) {
            if (res.includes(l.code)) {
              state.formData.country.controlProps.selectedOption = c;
              state.formData.language.controlProps.selectedOption = l;
              state.preferCountry = c;
              state.preferLanguage = l;
              matched = true;
              break;
            }
          }
        }
      }
      state.matched = matched;
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-market-selector {
  height: 100vh;
  color: $white;
  .e-background-image {
    height: 100%;
    &__content {
      @include grid-container;
    }
  }
  &__title {
    margin-bottom: 12px;
  }
  &__form {
    @include grid-block(2, 10, 1);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .s-dynamic-form {
    &__item-control {
      .e-dropdown {
        &__selection {
          background: $white;
          color: $black;
        }
      }
    }
  }
  @include tablet-landscape {
    &__form {
      @include grid-block(2, 7, 1);
      .e-site-button {
        align-self: flex-start;
        min-width: 190px;
      }
    }
  }
}
</style>
