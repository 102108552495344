<template>
  <div class="c-market-detector" v-if="visible && !$isOnApp($route)">
    <div class="c-market-detector__main" :class="mainClasses">
      <icon class="c-market-detector__close" name="close" @click="onClose" />
      <jss-rich-text class="c-market-detector__title" :field="fields.title" tag="div" />
      <div class="c-market-detector__form">
        <dropdown :options="countries" :selected-option="selectedCountry" @change="onCountryChange" />
        <dropdown :options="selectedCountry?.data.languages ?? []" :selected-option="selectedLanguage" @change="onLanguageChange" />
      </div>
      <site-button class="c-market-detector__go" :fields="fields.button?.fields" @click="switchMarket" />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from 'vue';
import { getCountries, getPageAlpha2Code, themeClass } from '@/utils/site-utils';
import { equalString } from '@/utils/string-utils';
import api from '@/api';
import { gtmPush } from '@/utils/gtm-utils';
import { webStorage } from '@/utils/web-storage';
import { S_MARKET_DETECTOR_CLOSED } from '@/utils/web-storage-keys';

export default {
  name: 'MarketDetector',
  props: {
    fields: {
      type: Object
    },
    /**@type SitePage*/
    page: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const countries = getCountries(props.page);
    const selectedCountry = countries.find((x) => x.code === getPageAlpha2Code(props.page));
    const selectedLanguage = selectedCountry?.languages.find((x) => equalString(x.code, props.page.itemLanguage));
    const state = reactive({
      visible: false,
      countries,
      selectedCountry,
      selectedLanguage,
      preferCountry: selectedCountry,
      preferLanguage: selectedLanguage
    });
    const computes = {
      mainClasses: computed(() => [themeClass(props, 'yellow')])
    };
    const methods = {
      switchMarket() {
        const { code } = state.selectedLanguage;
        if (!code) return;
        const { origin, pathname, search, hash } = window.location;
        let newPath;
        const reg = /^\/(en|([a-z]{2}-[a-z]{2}))\//gi;
        const reg1 = /^\/(en|([a-z]{2}-[a-z]{2}))$/gi;
        if (reg.test(pathname)) {
          newPath = pathname.replace(reg, `/${code}/`);
        } else if (reg1.test(pathname)) {
          newPath = pathname.replace(reg1, `/${code}`);
        } else {
          newPath = `/${code}${pathname}`;
        }
        gtmPush({
          event: 'market_selector',
          event_label: code
        });
        setTimeout(() => {
          webStorage.set(S_MARKET_DETECTOR_CLOSED, true);
          window.location.href = `${origin}${newPath}${search}${hash}`;
        }, 100);
      },
      onCountryChange(e, option) {
        state.selectedCountry = option;
        if (option === state.preferCountry) {
          state.selectedLanguage = state.preferLanguage;
        } else {
          const { languages } = option.data || {};
          if (languages.length > 0) {
            state.selectedLanguage = languages[0];
          }
        }
      },
      onLanguageChange(e, option) {
        state.selectedLanguage = option;
      },
      onClose(e) {
        state.visible = false;
        webStorage.set(S_MARKET_DETECTOR_CLOSED, true);
        document.documentElement.classList.remove('market-detector-open');
      }
    };
    onMounted(async () => {
      const hasClosed = webStorage.get(S_MARKET_DETECTOR_CLOSED);
      if (hasClosed) return;
      const [res, ex] = await api.web.ipDetect();
      if (!res?.length) return;
      let matched = false;
      const visible = res.every((x) => !equalString(props.page.itemLanguage, x));
      if (visible) {
        for (let c of state.countries) {
          for (let l of c.data.languages) {
            if (res.includes(l.code)) {
              state.selectedCountry = c;
              state.selectedLanguage = l;
              state.preferCountry = c;
              state.preferLanguage = l;
              matched = true;
              break;
            }
          }
        }
      }
      state.visible = matched;
      if (matched) {
        document.documentElement.classList.add('market-detector-open');
      }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-market-detector {
  &__main {
    @include component-themes;
    position: fixed;
    z-index: 12;
    width: 100%;
    height: 292px;
    background-color: $grey-neutral;
    color: $white;
    padding: $space-40 $space-16 $space-16;
  }
  &__close {
    position: absolute;
    top: $space-16;
    right: $space-16;
    cursor: pointer;
  }
  &__title {
    width: 100%;
    text-align: start;
  }
  .e-dropdown {
    margin-top: $space-16;
    &__error-box {
      display: none;
    }
  }
  &__go {
    width: 100% !important;
    margin-top: $space-16;
  }
  @include desktop {
    height: 85px;
    &__main {
      height: 85px;
      display: flex;
      align-items: center;
      padding: $space-16;
    }
    &__title {
      width: 50vw;
      flex-shrink: 0;
      text-align: end;
      padding-right: $space-16;
    }
    &__form {
      display: flex;
      flex-shrink: 0;
    }
    .e-dropdown {
      width: grid-width(3);
      margin-top: 0;
      margin-right: 20px;
    }
    &__close {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &__go {
      width: fit-content !important;
      margin-top: 0;
      margin-left: 10px;
      flex-shrink: 0;
      .e-icon {
        display: flex !important;
      }
    }
  }
}
</style>
