<template>
  <OverallSettings v-bind="$props">
    <div class="c-media-swiper-content" v-if="fields">
      <div class="c-media-swiper-content__swiper swiper" ref="swiperEl">
        <div class="swiper-wrapper">
          <div class="swiper-slide c-media-swiper-content-item" v-for="(item, index) in items" :key="item.id">
            <AdaptiveMedia
              class="c-media-swiper-content__media"
              :field="item.fields.media"
              :showPlayButton="true"
              :options="videoOptions"
              :replay="item.fields.replay"
              :duration="imageSwiperDelay"
              :animationType="'scale-up'"
              @ended="(e) => next(e, index)"
              ref="mediaEl"
            >
              <div class="c-media-swiper-content__progress" v-if="$isNullOrEmpty(item.fields.media.video?.value)">
                <div class="c-media-swiper-content__progress-content" :style="{ animation: currentIndex === index ? `slide-in ${imageSwiperDelay}ms forwards linear` : none }"></div>
              </div>
            </AdaptiveMedia>
          </div>
        </div>
        <template v-if="largeThanTablet() && items?.length > 1">
          <div class="c-media-swiper-content__nav c-media-swiper-content__nav-left" @click.prevent="toPre"><Icon name="left" /></div>
          <div class="c-media-swiper-content__nav c-media-swiper-content__nav-right" @click.prevent="toNext"><Icon name="right" /></div>
        </template>
      </div>
      <div class="c-media-swiper-content__description-pc" v-if="largeThanTablet()">
        <JssRichText class="c-media-swiper-content__description-pc-title" :field="currentItem?.fields?.title" />
        <JssRichText class="c-media-swiper-content__description-pc-body" :field="currentItem?.fields?.body" />
        <div class="c-media-swiper-content__description-pc-pagination" v-if="items?.length > 1">
          <span class="c-media-swiper-content__description-pc-pagination-current">{{ String.prototype.padStart.call(currentIndex + 1, 1, '0') }}</span>
          <span class="c-media-swiper-content__description-pc-pagination-line"> / </span>
          <span class="c-media-swiper-content__description-pc-pagination-total">{{ String.prototype.padStart.call(fields.items?.length, 1, '0') }}</span>
        </div>
      </div>
      <div class="c-media-swiper-content__description-mobile" v-else>
        <div class="c-media-swiper-content__description-mobile-top">
          <JssRichText class="c-media-swiper-content__description-mobile-title" :field="currentItem?.fields?.title" />
          <div class="c-media-swiper-content__description-mobile-pagination" v-if="items.length > 1">
            <span class="c-media-swiper-content__description-mobile-pagination-current">{{ String.prototype.padStart.call(currentIndex + 1, 1, '0') }}</span>
            <span class="c-media-swiper-content__description-mobile-pagination-line"> / </span>
            <span class="c-media-swiper-content__description-mobile-pagination-total">{{ String.prototype.padStart.call(fields.items?.length, 1, '0') }}</span>
          </div>
        </div>
        <JssRichText class="c-media-swiper-content__description-mobile-body" :field="currentItem?.fields?.body" />
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { onMounted, onBeforeUnmount, nextTick, toRefs, reactive, computed, inject } from 'vue';
import { largeThanTablet } from '@/utils/dom-utils';
import { settingValue } from '@/utils/site-utils';
import { deepCopy, isNullOrEmpty } from '@/utils/obj-utils';
import Swiper from 'swiper';
export default {
  name: 'MediaSwiperContent',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const state = reactive({
      swiper: null,
      swiperEl: null,
      mediaEl: null,
      currentIndex: 0,
      timer: null,
      items: [],
      videoOptions: {
        autoPlay: true,
        preload: 'auto',
        controlBar: {
          // 设置控制条组件
          children: [
            { name: 'playToggle' },
            { name: 'currentTimeDisplay' },
            { name: 'timeDivider' },
            { name: 'durationDisplay' },
            { name: 'progressControl' },
            {
              name: 'volumePanel',
              inline: false
            },
            { name: 'FullscreenToggle' }
          ]
        }
      }
    });
    const computes = {
      imageSwiperDelay: computed(() => settingValue(props.fields.imageSwiperDelay, 2000)),
      currentItem: computed(() => props.fields.items[state.currentIndex])
    };
    const methods = {
      largeThanTablet,
      buildSwiper: () => {
        state.swiper && state.swiper.destroy(true, true);
        state.swiper = new Swiper(state.swiperEl, {
          slidesPerView: largeThanTablet() ? 1 : 'auto',
          spaceBetween: largeThanTablet() ? 0 : 8,
          centeredSlides: true,
          shortSwipes: largeThanTablet() ? false : true,
          on: {
            slideChange: (swiper) => {
              state.items.forEach((i) => {
                i.fields.replay = false;
              });
              if (isNullOrEmpty(state.mediaEl[state.currentIndex].video.value)) {
                clearTimeout(state.timer);
              } else {
                state.mediaEl[state.currentIndex].playerRef.player.pause();
              }
              state.currentIndex = swiper.realIndex;
              if (isNullOrEmpty(state.mediaEl[swiper.realIndex].video.value)) {
                state.items[swiper.realIndex].fields.replay = state.items[swiper.realIndex].fields.media.fields.enterAnimation.value;
                methods.initProgress();
              } else {
                state.mediaEl[swiper.realIndex].playerRef.player.play();
              }
            }
          }
        });
      },
      toPre: () => {
        state.currentIndex === 0 ? state.swiper.slideToLoop(props.fields.items?.length - 1) : state.swiper.slideToLoop(state.currentIndex - 1);
      },
      toNext: () => {
        state.currentIndex + 1 >= props.fields.items?.length ? state.swiper.slideToLoop(0) : state.swiper.slideToLoop(state.currentIndex + 1);
      },
      initProgress: () => {
        state.timer = setTimeout(() => {
          methods.toNext();
        }, computes.imageSwiperDelay.value);
      },
      next: (e, index) => {
        if (!e.isFullscreen_ && state.currentIndex === index) {
          methods.toNext();
        }
      }
    };
    onMounted(async () => {
      state.items = deepCopy(props.fields.items);
      state.items.forEach((i) => {
        i.fields.replay = false;
      });
      await nextTick();
      if (isNullOrEmpty(state.mediaEl[state.currentIndex].video.value)) {
        methods.initProgress();
      }
      methods.buildSwiper();
    });
    onBeforeUnmount(() => {
      state.swiper && state.swiper.destroy(true, true);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-media-swiper-content {
  $c: &;
  position: relative;
  width: 100%;
  &__swiper {
    height: grid-width-m(math.div(50, 4));
    padding: 0 grid-width-m(1);
    .swiper-wrapper {
      height: 100%;
      width: 100%;
      .swiper-slide {
        overflow: hidden;
      }
      #{$c}__media {
        height: 100%;
        width: 100%;
        video,
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &__description-mobile {
    width: grid-width-m(10);
    margin: 48px auto 0;
    padding-bottom: 60px;
    &-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &-title {
      flex: 3;
      word-break: break-word;
    }
    &-pagination {
      flex: 1;
      text-align: end;
      margin-top: 10px;
      color: #727272;
    }
    &-body {
      word-break: break-word;
      margin-top: 20px;
      color: #757575;
    }
  }
  &__progress {
    width: 100%;
    height: 5px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #f2f1f0;
    &-content {
      width: 100%;
      height: 5px;
      position: absolute;
      background-color: #fdef00;
    }
    &-point {
      width: 12px;
      height: 12px;
      background-color: $white;
      border-radius: 12px;
      margin-left: -6px;
      margin-top: -3px;
    }
  }
  .e-video-player {
    z-index: 2;
    .video-js {
      .vjs-control-bar {
        position: absolute;
        bottom: 12px;
        left: 0;
        right: 0;
        z-index: 3;
        opacity: 1;
        background-color: transparent;
        .vjs-play-control {
          position: absolute;
          top: 0;
          left: 6px;
        }
        .vjs-current-time {
          width: fit-content;
          position: absolute;
          top: 0;
          left: 56px;
          display: inline-block;
        }
        .vjs-time-divider {
          position: absolute;
          top: 0;
          left: 86px;
          display: inline-block;
        }
        .vjs-duration {
          width: fit-content;
          position: absolute;
          top: 0;
          left: 96px;
          display: inline-block;
        }
        .vjs-volume-panel {
          width: 40px;
          position: absolute;
          top: 0;
          right: 36px;
          .vjs-volume-control {
            display: none;
          }
        }
        .vjs-fullscreen-control {
          position: absolute;
          top: 0;
          right: 6px;
        }
        .vjs-progress-control {
          height: 16px;
          width: 100%;
          position: absolute;
          bottom: -3px;
          left: 0;
          transform: translateY(100%);
          .vjs-progress-holder {
            height: 8px;
            width: 100%;
            margin: 0;
            background-color: $grey-light;
          }
        }
      }
      .vjs-play-progress {
        background-color: $yellow;
        &:before {
          display: none;
        }
      }
      .vjs-load-progress div {
        background: transparent;
      }
    }
    &__play-btn {
      color: $white;
    }
  }
  @include tablet-landscape {
    width: 100%;
    &__swiper {
      position: relative;
      height: grid-width(math.div(20 * 9, 16));
      width: grid-width(20);
      margin: 0 auto;
      padding: 0;
      overflow: hidden;
      &:hover {
        #{$c}__nav {
          display: flex;
        }
      }
    }
    &__description-pc {
      width: grid-width(20);
      margin: 80px auto 0;
      display: flex;
      flex-direction: row;
      padding-bottom: 96px;
      &-title {
        flex: 1;
        word-break: break-word;
        font-size: 32px;
        color: $black;
      }
      &-body {
        width: grid-width(8);
        word-break: break-word;
        color: #757575;
      }
      &-pagination {
        flex: 1;
        text-align: end;
        color: #727272;
      }
    }
    &__nav {
      width: 48px;
      height: 48px;
      border: 1px solid $yellow;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      color: $white;
      cursor: pointer;
      display: none;
      justify-content: center;
      align-items: center;
      &-left {
        left: 48px;
      }
      &-right {
        right: 48px;
      }
    }
    .e-video-player {
      .video-js {
        .vjs-control-bar {
          bottom: 12px;
          &:after {
            height: 160px;
          }
          .vjs-play-control {
            left: 20px;
            display: none;
          }
          .vjs-current-time {
            left: 56px;
            display: none;
          }
          .vjs-time-divider {
            display: none;
          }
          .vjs-duration {
            display: none;
          }
          .vjs-volume-panel {
            right: 72px;
            display: none;
          }
          .vjs-fullscreen-control {
            right: 32px;
            display: none;
          }
        }
        .vjs-play-progress {
          background-color: $yellow;
        }
        .vjs-load-progress div {
          background: transparent;
        }
      }
    }
    &__media:hover {
      .e-video-player {
        .video-js {
          .vjs-control-bar {
            .vjs-play-control {
              display: block;
            }
            .vjs-current-time {
              display: block;
            }
            .vjs-time-divider {
              display: block;
            }
            .vjs-duration {
              display: block;
            }
            .vjs-volume-panel {
              display: block;
            }
            .vjs-fullscreen-control {
              display: block;
            }
          }
        }
      }
    }
  }
  @keyframes slide-in {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
}
</style>
