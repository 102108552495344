<template>
  <overall-settings v-bind="$props">
    <div class="c-lotus-story" v-if="fields" :class="rootClasses" ref="superchargedEl">
      <jss-rich-text class="c-lotus-story-pc-title" :field="fields.title" tag="div" />
      <JssRichText class="c-lotus-story-pc-first-desc" :field="fields.firstDesction" tag="div" />
      <JssRichText class="c-lotus-story-pc-second-desc" :field="fields.secondDesction" tag="div" />
      <JssRichText class="c-lotus-story-pc-third-desc" :field="fields.thirdDesction" tag="div" />
      <JssRichText class="c-lotus-story-pc-tips" :field="fields.tips" tag="div" />
      <JssRichText class="c-lotus-story-pc-second-desc" :field="fields.fourthDesction" tag="div" />
      <JssRichText class="c-lotus-story-pc-second-desc" :field="fields.fifthDesction" tag="div" />
      <JssRichText class="c-lotus-story-pc-second-desc" :field="fields.sixthDesction" tag="div" />
    </div>
  </overall-settings>
</template>

<script>
import { computed } from 'vue';
import { themeClass } from '@/utils/site-utils';
export default {
  name: 'SuperCharged',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    console.log('props.fields', props.fields);
    const computes = {
      rootClasses: computed(() => {
        return [themeClass(props)];
      })
    };
    return {
      ...computes
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
@import '../styles/rte/fontFamily.scss';
.c-lotus-story {
  $c: &;
  background: #000;
  color: #fff;
  width: 100%;
  padding: grid-width-m(2) grid-width-m(1);
  @include component-themes;
  &-pc-title {
    @include h2;
    font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
    color: #fff200;
  }
  &-pc-first-desc {
    margin-left: grid-width-m(2);
    margin-top: grid-width-m(2);
    width: grid-width-m(8);
    @include h7;
    line-height: 1.5;
  }
  &-pc-second-desc {
    margin-left: grid-width-m(2);
    margin-top: grid-width-m(1);
    width: grid-width-m(8);
    @include h7;
    line-height: 1.5;
  }
  &-pc-third-desc {
    margin-left: 0;
    margin-top: grid-width-m(1);
    width: grid-width-m(8);
    color: #fff200;
    @include h6;
    line-height: 1.5 !important;
  }
  &-pc-tips {
    margin-left: 0;
    margin-top: grid-width-m(0.2);
    width: grid-width-m(7);
    @include h9;
  }
  &.theme {
    &-white,
    &-yellow,
    &-white-yellow,
    &-yellow-white {
      #{$c}-pc-title {
        color: $black;
      }

      #{$c}-pc-third-desc {
        color: $black;
      }
    }
    &-black,
    &-night-blue,
    &-neutral-grey {
      #{$c}-pc-title {
        color: $white;
      }

      #{$c}-pc-third-desc {
        color: $white;
      }
    }
  }
  @include tablet-landscape {
    width: 100%;
    padding: grid-width(3) grid-width(5) grid-width(3) grid-width(5);
    &-pc-first-desc {
      margin-left: grid-width(6);
      margin-top: grid-width(1);
      width: grid-width(8);
      @include h9;
    }
    &-pc-second-desc {
      margin-left: grid-width(6);
      margin-top: grid-width(0.5);
      width: grid-width(8);
      @include h9;
    }
    &-pc-third-desc {
      margin-left: grid-width(5);
      margin-top: grid-width(0.5);
      width: grid-width(7);
      @include h7;
      line-height: 1.5 !important;
    }
    &-pc-tips {
      margin-left: grid-width(5);
      margin-top: grid-width(0.2);
      width: grid-width(7);
      @include h9;
    }
  }
}
html.rtl {
  .c-lotus-story {
    &-pc-first-desc,
    &-pc-second-desc {
      margin-left: 0;
      margin-right: grid-width-m(2);
    }
    &-pc-third-desc,
    &-pc-tips {
      margin-right: 0;
    }
    @include tablet-landscape {
      &-pc-first-desc,
      &-pc-second-desc {
        margin-left: 0;
        margin-right: grid-width(6);
      }
      &-pc-third-desc,
      &-pc-tips {
        margin-left: 0;
        margin-right: grid-width(5);
      }
    }
  }
}
html[lang='ja-JP'] {
  .c-lotus-story {
    &-pc-title {
      font-family: lotusFontFamily('Noto Sans JP Regular'), sans-serif;
    }
  }
}
html[lang='ko-KR'] {
  .c-lotus-story {
    &-pc-title {
      font-family: lotusFontFamily('Noto Sans KR Regular'), sans-serif;
    }
  }
}
</style>
