<template>
  <OverallSettings v-bind="$props">
    <div class="c-large-image-content" v-if="fields" ref="rootEl">
      <div class="c-large-image-content__content" ref="wrapperEl">
        <div class="c-large-image-content__large-image-group" :class="fields.reverse.value ? 'right' : 'left'" ref="largeImageEl">
          <AdaptiveBackImage class="c-large-image-content__media-container-image" :pc-image="fields.largeImage" :mobile-image="fields.mobileLargeImage" />
        </div>
        <div class="c-large-image-content__text-image-group" :class="fields.reverse.value ? 'left' : 'right'" ref="smallImageEl">
          <JssRichText tag="div" class="c-large-image-content__text-image-group-body" :field="fields.body" />
          <JssRichText tag="div" class="c-large-image-content__text-image-group-title" :field="fields.title" />
          <div class="c-large-image-content__text-image-group-media-container">
            <AdaptiveBackImage class="c-large-image-content__text-image-group-media-container-image" :pc-image="fields.smallImage" :mobile-image="fields.mobileSmallImage" />
          </div>
        </div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { reactive, toRefs, onMounted, onUnmounted } from 'vue';
import { loadScrollMagic } from '@/utils/site-utils';
import { isDesktop } from '@/utils/dom-utils';
import gsap from 'gsap';
export default {
  name: 'LargeImageContent',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props, context) {
    let ScrollMagic, controller, scene, tl;
    const state = reactive({
      rootEl: null,
      wrapperEl: null,
      largeImageEl: null,
      smallImageEl: null
    });
    onMounted(() => {
      if (isDesktop()) {
        ScrollMagic = loadScrollMagic();
        controller = new ScrollMagic.Controller();
        tl = gsap.timeline();
        tl.to(
          state.largeImageEl,
          {
            y: -200,
            opacity: 1
          },
          'first'
        ).to(
          state.smallImageEl,
          {
            y: -200,
            opacity: 1
          },
          'second'
        );
        scene = new ScrollMagic.Scene({
          triggerElement: state.rootEl,
          triggerHook: 0,
          duration: state.wrapperEl.offsetHeight
        })
          .setTween(tl)
          .setPin(state.wrapperEl)
          .addTo(controller);
      }
    });
    onUnmounted(() => {
      scene.destroy(true);
      controller.destroy(true);
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-large-image-content {
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__large-image-group {
    width: grid-width(20);
    height: grid-width(20);
    margin: 0 auto;
    order: 2;
    > div {
      height: 100%;
    }
  }
  &__text-image-group {
    width: grid-width(20);
    &-media-container {
      height: grid-width(math.div(20 * 9, 16));
      margin-bottom: $space-40;
      > div {
        height: 100%;
      }
    }
    margin: 0 auto;
    order: 1;
    &-title {
      margin-top: $space-20;
      margin-bottom: $space-40;
    }
  }
  &__media-container-image {
    width: 100%;
  }

  @include tablet-landscape {
    &__content {
      flex-direction: row;
      justify-content: space-evenly;
      .left {
        order: 1;
      }
      .right {
        order: 2;
      }
    }
    &__large-image-group {
      width: grid-width(9);
      height: grid-width(12);
      margin: 0;
      opacity: 0;
      padding-top: 200px;
    }
    &__text-image-group {
      width: grid-width(11);
      margin: 0;
      opacity: 0;
      padding-top: 200px;
      &-title {
        margin-top: $space-40;
        margin-bottom: $space-40;
      }
      &-media-container {
        height: grid-width(math.div(11 * 9, 16));
      }
    }
  }
}
</style>
