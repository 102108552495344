<template>
  <OverallSettings v-bind="$props">
    <div class="c-list-content" v-if="!$isNullOrEmpty(fields)">
      <div class="c-list-content__content">
        <div class="c-list-content__mobile" v-if="!largeThanTablet()">
          <div class="c-list-content__title" :class="{ underLine: fields.hasLine?.value }">
            <Disclaimer :field="fields.title" :disclaimer="fields.titleDisclaimer" :showHover="true" v-if="fields.hasTitle?.value" />
          </div>
          <template v-if="fields.mobileSwipeable?.value">
            <div class="c-list-content__items-group" ref="swiperEl">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in fields.itemList" :key="item.id">
                  <div class="c-list-content__item-top">
                    <Disclaimer class="c-list-content__item-title" :field="item.fields.title" :disclaimer="item.fields.titleDisclaimer" :showHover="true" />
                    <AdaptiveMedia class="c-list-content__item-image" :field="item.fields.media" />
                    <Icon :svg="item.fields.svg?.value.svgCode" class="c-list-content__item-icon" />
                  </div>
                </div>
              </div>
            </div>
            <div class="c-list-content__pagination" v-if="itemsGroup.length > 1 && fields.hasNavigator?.value">
              <Icon :svg="fields.prevIcon?.value.svgCode" class="c-list-content__pagination-left" size="large" ref="prevEl" />
              <Icon :svg="fields.nextIcon?.value.svgCode" class="c-list-content__pagination-right" size="large" ref="nextEl" />
            </div>
            <div class="c-list-content__item-bottom" v-if="currentItem?.fields">
              <Disclaimer class="c-list-content__item-subtitle" :field="currentItem.fields.subtitle" :disclaimer="currentItem.fields.subtitleDisclaimer" :showHover="true" />
              <Disclaimer class="c-list-content__item-body" :field="currentItem.fields.body" :disclaimer="currentItem.fields.bodyDisclaimer" :showHover="true" />
              <div class="c-list-content__item-icons" v-if="currentItem.fields.countIconList?.length > 0">
                <div class="c-list-content__item-icon-group" v-for="icon in currentItem.fields.countIconList" :key="icon.id">
                  <Icon :svg="icon.fields.icon?.value.svgCode" class="c-list-content__item-icon-group-icon" />
                  <JssRichText class="c-list-content__item-icon-group-text" :field="icon.fields.count" />
                </div>
              </div>
              <div class="c-list-content__item-btns" v-if="currentItem.fields.buttons?.length > 0">
                <SiteButton class="c-list-content__item-btn" v-for="btn in currentItem.fields.buttons" :key="btn.id" v-bind="btn" />
              </div>
            </div>
          </template>
          <div class="c-list-content__item" v-for="item in fields.itemList" :key="item.id" v-else>
            <div class="c-list-content__item-top">
              <Disclaimer class="c-list-content__item-title" :field="item.fields.title" :disclaimer="item.fields.titleDisclaimer" :showHover="true" />
              <AdaptiveMedia class="c-list-content__item-image" :field="item.fields.media" />
              <Icon :svg="item.fields.svg?.value.svgCode" class="c-list-content__item-icon" />
            </div>
            <div class="c-list-content__item-bottom">
              <Disclaimer class="c-list-content__item-subtitle" :field="item.fields.subtitle" :disclaimer="item.fields.subtitleDisclaimer" :showHover="true" />
              <Disclaimer class="c-list-content__item-body" :field="item.fields.body" :disclaimer="item.fields.bodyDisclaimer" :showHover="true" />
              <div class="c-list-content__item-icons" v-if="item.fields.countIconList?.length > 0">
                <div class="c-list-content__item-icon-group" v-for="icon in item.fields.countIconList" :key="icon.id">
                  <Icon :svg="icon.fields.icon?.value.svgCode" size="small" class="c-list-content__item-icon-group-icon" />
                  <JssText class="c-list-content__item-icon-group-text" :field="icon.fields.count" />
                </div>
              </div>
              <div class="c-list-content__item-btns" v-if="item.fields.buttons?.length > 0">
                <SiteButton class="c-list-content__item-btn" v-for="btn in item.fields.buttons" :key="btn.id" v-bind="btn" />
              </div>
            </div>
          </div>
        </div>
        <div class="c-list-content__desktop" v-else>
          <div class="c-list-content__title" :class="{ underLine: fields.hasLine?.value, navOnly: !fields.hasTitle?.value }">
            <Disclaimer :field="fields.title" :disclaimer="fields.titleDisclaimer" v-if="fields.hasTitle?.value" :showHover="true" />
            <div class="c-list-content__pagination" v-if="itemsGroup.length > 1 && fields.hasNavigator?.value">
              <Icon :svg="fields.prevIcon?.value.svgCode" class="c-list-content__pagination-left" size="small" ref="prevEl" />
              <Icon :svg="fields.nextIcon?.value.svgCode" class="c-list-content__pagination-right" size="small" ref="nextEl" />
            </div>
          </div>
          <div class="c-list-content__items-group" :class="`c-list-content__item-count-${fields.countPerView?.fields.phrase.value}`" ref="swiperEl">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(items, index) in itemsGroup" :key="index">
                <div class="c-list-content__item" v-for="item in items" :key="item.id">
                  <div class="c-list-content__item-top">
                    <Disclaimer
                      class="c-list-content__item-title"
                      :field="item.fields.title"
                      :disclaimer="item.fields.titleDisclaimer"
                      :showHover="true"
                      :align="$equalString(item.id, fields.itemList[fields.itemList.length - 1].id) ? 'right' : 'left'"
                    />
                    <AdaptiveMedia class="c-list-content__item-image" :field="item.fields.media" />
                    <Icon :svg="item.fields.svg?.value.svgCode" class="c-list-content__item-icon" />
                  </div>
                  <div class="c-list-content__item-bottom">
                    <Disclaimer
                      class="c-list-content__item-subtitle"
                      :field="item.fields.subtitle"
                      :disclaimer="item.fields.subtitleDisclaimer"
                      :showHover="true"
                      :align="$equalString(item.id, fields.itemList[fields.itemList.length - 1].id) ? 'right' : 'left'"
                    />
                    <Disclaimer
                      class="c-list-content__item-body"
                      :field="item.fields.body"
                      :disclaimer="item.fields.bodyDisclaimer"
                      :showHover="true"
                      :align="$equalString(item.id, fields.itemList[fields.itemList.length - 1].id) ? 'right' : 'left'"
                    />
                    <div class="c-list-content__item-icons" v-if="item.fields.countIconList?.length > 0">
                      <div class="c-list-content__item-icon-group" v-for="icon in item.fields.countIconList" :key="icon.id">
                        <Icon :svg="icon.fields.icon?.value.svgCode" class="c-list-content__item-icon-group-icon" />
                        <jss-rich-text class="c-list-content__item-icon-group-text" :field="icon.fields.count" />
                      </div>
                    </div>
                    <div class="c-list-content__item-btns" v-if="item.fields.buttons?.length > 0">
                      <site-button
                        class="c-list-content__item-btn"
                        v-bind="btn"
                        :qt-data="{ news_title: $ifEmpty(item.fields.title.value, item.fields.subtitle.value) }"
                        v-for="btn in item.fields.buttons"
                        :key="btn.id"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-list-content__btns" v-if="fields.buttons?.length">
          <SiteButton class="c-list-content__btn" v-bind="btn" v-for="btn in fields.buttons" :key="btn.id" />
        </div>
      </div>
    </div>
  </OverallSettings>
</template>
<script>
import { reactive, onMounted, onBeforeUnmount, computed, toRefs, nextTick } from 'vue';
import { settingValue } from '@/utils/site-utils';
import { canUseDOM, largeThanTablet } from '@/utils/dom-utils';
import Swiper from 'swiper';
export default {
  name: 'ListContent',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    let swiper;
    const state = reactive({
      /**@type {HTMLElement}*/
      swiperEl: null,
      /**@type {Icon|null}*/
      prevEl: null,
      /**@type {Icon|null}*/
      nextEl: null,
      currentIndex: 0
    });
    const computes = {
      itemsGroup: computed(() => {
        let tmp = [];
        for (let i = 0; i < props.fields?.itemList?.length; i += Number(settingValue(props.fields?.countPerView))) {
          tmp.push(props.fields?.itemList.slice(i, i + Number(settingValue(props.fields?.countPerView))));
        }
        return tmp;
      }),
      currentItem: computed(() => props.fields?.itemList[state.currentIndex])
    };
    const methods = {
      largeThanTablet,
      buildSwiper: () => {
        swiper && swiper.destroy(true, true);
        swiper = new Swiper(state.swiperEl, {
          slidesPerView: 'auto',
          spaceBetween: largeThanTablet() ? 24 : 18,
          maxBackfaceHiddenSlides: 2,
          navigation: {
            prevEl: state.prevEl?.rootEl,
            nextEl: state.nextEl?.rootEl
          },
          on: {
            slideChange: (swiper) => {
              state.currentIndex = swiper.realIndex;
            }
          }
        });
      }
    };
    onMounted(() => {
      if (canUseDOM()) {
        document.addEventListener('resize', methods.buildSwiper);
        nextTick(() => {
          methods.buildSwiper();
        });
      }
    });
    onBeforeUnmount(() => {
      document.removeEventListener('resize', methods.buildSwiper);
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@use 'sass:math';
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
.c-list-content {
  @include grid-container;
  width: 100vw;
  overflow: hidden;
  &__content {
    @include grid-block(2, 10);
    position: relative;
  }
  &__title {
    padding-bottom: $space-32;
    font-size: 32px;
    line-height: 32px;
    &.underLine {
      margin-bottom: $space-48;
      border-bottom: 2px solid #e3e3e3;
    }
  }
  &__pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 12px 0;
    > .e-icon {
      width: 52px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.swiper-button-disabled {
        color: $grey-neutral;
        cursor: not-allowed;
      }
    }
  }
  &__item {
    margin-bottom: $space-40;
    &-title {
      margin-bottom: $space-20;
    }
    &-subtitle {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }
    &-bottom {
      margin-top: $space-24;
    }
    &-image {
      width: 100%;
      height: grid-width-m(math.div(10 * 2, 3)) !important;
      overflow: hidden;
      .e-adaptive-media__main,
      .e-adaptive-media__image {
        background: transparent;
      }
    }
    &-icon {
      &.e-icon > svg {
        width: 64px;
        height: 64px;
        fill: transparent;
      }
    }
    &-body {
      margin-top: $space-8;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    &-icons {
      display: flex;
      flex-direction: row;
      margin-top: $space-24;
    }
    &-icon-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: $space-40;
      &-icon {
        margin-right: $space-8;
      }
      &-text {
        font-size: 20px;
      }
    }
    &-btns {
      margin-top: $space-24;
    }
  }
  &__btns {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    margin-top: 40px;
  }

  @include tablet-landscape {
    &__title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &.navOnly {
        justify-content: flex-end;
      }
    }
    &__content {
      @include grid-block(3, 20);
    }
    &__items-group {
      .swiper-slide {
        display: flex;
        flex-direction: row;
        gap: $space-24;
      }
    }
    &__item {
      margin-bottom: 0;
      &-count {
        &-2 {
          .c-list-content__item {
            width: 50%;
            &-image {
              height: grid-width(math.div(20 * 2, 6)) !important;
            }
          }
        }
        &-3 {
          .c-list-content__item {
            width: 33%;
            &-image {
              height: grid-width(math.div(20 * 2, 9)) !important;
            }
          }
        }
        &-4 {
          .c-list-content__item {
            width: 25%;
            &-image {
              height: grid-width(math.div(20 * 2, 12)) !important;
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      padding: 0;
      margin: 0;
      & > .e-icon {
        width: auto;
        height: auto;
      }
      &-left {
        transition: all 0.2s cubic-bezier(0.1, 0.1, 0.1, 1);
        transform: scale(1);
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
        }
      }
      &-right {
        transition: all 0.2s cubic-bezier(0.1, 0.1, 0.1, 1);
        transform: scale(1);
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
        }
      }
      &-item {
        display: flex;
        padding: 0 $space-12;
      }
    }
    &__btns {
      flex-direction: row;
      margin-top: 64px;
    }
  }
}
</style>
