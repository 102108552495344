<template>
  <overall-settings v-bind="$props">
    <div class="c-lotus-story-video" v-if="fields">
      <VideoPlayer :options="videoOptions" v-if="fields.video.value" />
    </div>
  </overall-settings>
</template>

<script>
import { computed } from 'vue';
export default {
  name: 'LotusStoryVideo',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const computes = {
      videoOptions: computed(() => methods.buildVideoOps(props?.fields.video))
    };
    const methods = {
      buildVideoOps(video) {
        return {
          controls: false,
          autoplay: true,
          muted: true,
          loop: true,
          disableFullscreen: props.fields.videoDisablefullscreen?.value ?? false,
          disableUnmute: props.fields.videoDisableUnmute?.value ?? false,
          sources: [
            {
              type: 'video/mp4',
              src: video.value
            }
          ]
        };
      }
    };
    return {
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
@import '../styles/function';
@import '../styles/rte/fontFamily.scss';
.c-lotus-story-video {
  width: 100vw;
  height: 100vh;
}
</style>
