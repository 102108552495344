<template>
  <div class="c-marquee-text">
    <div class="c-marquee-text__content" v-if="fields" ref="txtEl">
      <div class="c-marquee-text__title" ref="titleEl" v-for="item in 2" :key="item">
        <JssText class="c-marquee-text__title-txt" :field="fields.body" tag="div" />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue';
import gsap from 'gsap';
export default {
  name: 'MarqueeText',
  props: {
    fields: {
      type: Object
    }
  },
  setup(props) {
    const state = reactive({
      /**@type {HTMLElement}*/
      txtEl: null,
      /**@type {HTMLElement}*/
      titleEl: null
    });

    onMounted(() => {
      let titleWidth = state.titleEl.clientWidth;
      let tl = gsap.timeline({ repeat: -1 });
      if (props.fields.body.value) {
        tl.to(state.txtEl, {
          duration: 30,
          x: -titleWidth,
          ease: 'none'
        }).play();
      }
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/mixin';
.c-marquee-text {
  width: 100%;
  overflow: hidden;
  padding: $space-10 0;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  &__content {
    white-space: nowrap;
  }
  &__title {
    display: inline-block;
    &-txt {
      @include h1;
    }
  }
}
</style>
