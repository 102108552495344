<template>
  <overall-settings v-bind="$props">
    <div class="c-lotus-story-limit" ref="LotusStoryLimit">
      <div class="trigger-content" ref="trigger_content"></div>
      <kv-slide-bg class="kv-slide-bg--page" :image="fields.image" />
      <section class="c-lotus-story-limit__outside" :class="{ 'c-lotus-story-limit__outside--animate': addStatus == 1 }" ref="cic_mt_content">
        <span class="c-lotus-story-limit__topTitle" v-if="fields.topTitle" ref="topTitle" :class="{ 'title-center': step1, 'title-right': step2 }">
          <jss-rich-text v-if="fields.topTitle" :field="fields.topTitle" tag="div" />
        </span>
        <span class="c-lotus-story-limit__bottomTitle" v-if="fields.bottomTitle" ref="bottomTitle" :class="{ 'title-center': step1, 'title-left': step2 }">
          <jss-rich-text v-if="fields.bottomTitle" :field="fields.bottomTitle" tag="div" />
        </span>
      </section>
      <div class="c-lotus-story-limit-btn-content">
        <div class="c-lotus-story-limit-btn-inner-content">
          <AnimatedContent class="c-lotus-story-limit-btn-inner-content-animate" type="bottom-fade-in" :delay="50">
            <SiteButton class="c-lotus-story-limit-btn" v-bind="fields.leftButton" />
          </AnimatedContent>
          <AnimatedContent class="c-lotus-story-limit-btn-inner-content-animate" type="bottom-fade-in" :delay="100">
            <SiteButton class="c-lotus-story-limit-btn" v-bind="fields.centerButton" />
          </AnimatedContent>
          <AnimatedContent class="c-lotus-story-limit-btn-inner-content-animate" type="bottom-fade-in" :delay="150">
            <SiteButton class="c-lotus-story-limit-btn" v-bind="fields.rightButton" />
          </AnimatedContent>
        </div>
      </div>
    </div>
  </overall-settings>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from 'vue';
import { isDesktop, isDesktopLarge, isTabletLandscape } from '@/utils/dom-utils';
import { loadScrollMagic, getGlobalConfigs } from '@/utils/site-utils';
import { debounce } from 'lodash';
/**
 * @typedef LotusStoryLimitFields
 * @property {GlobalSettingEntry} theme
 * */

export default {
  name: 'LotusStoryLimit',
  props: {
    /**@type LotusStoryLimitFields*/
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    const [rtl] = getGlobalConfigs(props.page, 'rtl');
    let ScrollMagic, controller, scene;
    const state = reactive({
      trigger_content: null,
      LotusStoryLimit: null,
      cic_mt_content: null,
      topTitle: null,
      bottomTitle: null,

      addStatus: 0,
      kvslidebgpage: null,

      step1: false,
      step2: false
    });

    const methods = {
      isDesktop: isDesktop,
      isDesktopLarge: isDesktopLarge,
      isTabletLandscape: isTabletLandscape,

      resizePageSet: debounce(async (e) => {
        controller.updateScene(scene, true);
      }, 300),

      async pageInit() {
        if (!state.LotusStoryLimit) return;
        ScrollMagic = loadScrollMagic();
        controller = new ScrollMagic.Controller();

        scene = new ScrollMagic.Scene({
          triggerElement: state.LotusStoryLimit,
          offset: isDesktop() || isDesktopLarge() || isTabletLandscape() ? -64 : -52,
          triggerHook: 0,
          duration: state?.LotusStoryLimit?.offsetHeight ?? '100%'
        })
          .setPin(state.LotusStoryLimit)
          .addTo(controller);

        scene.on('progress', (e) => {
          if (isDesktop() || isDesktopLarge() || isTabletLandscape()) {
            const docWidth = state.LotusStoryLimit.clientWidth,
              topTitle_width = state.topTitle.clientWidth,
              topTitle_left = docWidth * e.progress;

            if (rtl?.value) {
              if (topTitle_width + topTitle_left <= docWidth) {
                state.topTitle.style['margin-right'] = e.progress * 100 + '%';
                state.bottomTitle.style['margin-left'] = e.progress * 100 + '%';
              } else {
                state.topTitle.style['margin-right'] = docWidth - topTitle_width + 'px';
                state.bottomTitle.style['margin-left'] = docWidth - topTitle_width + 'px';
              }
            } else {
              if (topTitle_width + topTitle_left <= docWidth) {
                state.topTitle.style['margin-left'] = e.progress * 100 + '%';
                state.bottomTitle.style['margin-right'] = e.progress * 100 + '%';
              } else {
                state.topTitle.style['margin-left'] = docWidth - topTitle_width + 'px';
                state.bottomTitle.style['margin-right'] = docWidth - topTitle_width + 'px';
              }
            }
          } else {
            if (rtl?.value) {
              state.topTitle.style['margin-right'] = e.progress * 100 + '%';
              state.bottomTitle.style['margin-left'] = e.progress * 100 + '%';
            } else {
              state.topTitle.style['margin-left'] = e.progress * 100 + '%';
              state.bottomTitle.style['margin-right'] = e.progress * 100 + '%';
            }
          }
        });
      }
    };

    onMounted(() => {
      methods.pageInit();
      window.addEventListener('resize', methods.resizePageSet, { passive: true });
    });

    onUnmounted(() => {
      window.removeEventListener('resize', methods.resizePageSet);

      controller.destroy();
      scene.destroy();
    });

    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.trigger-content {
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 110%;
  overflow: hidden;
  opacity: 0;
}
.kv-slide-bg--page {
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.c-lotus-story-limit {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.c-lotus-story-limit__outside {
  position: relative;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 3;

  &.c-lotus-story-limit__outside--animate {
    transform: translateY(0);
  }
}
.c-lotus-story-limit__topTitle {
  display: block;
  position: relative;
  font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
  padding: 60px 0 0 0;
  font-size: 90px;
  color: #fff200;
  line-height: 1;
  white-space: nowrap;
  letter-spacing: -10px;
  transform: translateX(-50%);
  clear: both;
  float: left;
  left: 0;

  &.title-center {
    transform: translateX(-50%);
  }
  &.title-right {
    transform: none;
  }
}

.c-lotus-story-limit__bottomTitle {
  display: block;
  position: relative;
  font-family: lotusFontFamily('Overpass Lotus Headlines'), sans-serif;
  padding: 10px 0 0 0;
  transform: translateX(50%);
  font-size: 90px;
  color: #fff200;
  line-height: 1;
  letter-spacing: -10px;
  clear: both;
  float: right;
  right: 0;

  &.title-center {
    transform: translateX(50%);
  }
  &.title-left {
    transform: none;
  }
}
.c-lotus-story-limit-btn-content {
  position: absolute;
  bottom: 164px;
  z-index: 10;
  width: 100%;
}
.c-lotus-story-limit-btn-inner-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.c-lotus-story-limit-btn-inner-content-animate {
  width: 80%;
}
.c-lotus-story-limit-btn {
  width: 100%;
}

@include tablet-landscape {
  .trigger-content {
    height: 100%;
  }
  .c-lotus-story-limit {
    height: 100vh;
  }
  .c-lotus-story-limit__outside {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 3;
  }
  .c-lotus-story-limit__topTitle {
    padding: 60px 0 0 0;
    transform: none;
    font-size: 220px;
  }
  .c-lotus-story-limit__bottomTitle {
    transform: none;
    font-size: 220px;
  }
  .c-lotus-story-limit-btn-content {
    bottom: 74px;
  }
  .c-lotus-story-limit-btn-inner-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
  }
  .c-lotus-story-limit-btn-inner-content-animate {
    width: auto;
  }
}
html.rtl {
  .c-lotus-story-limit {
    &__topTitle {
      float: right;
      transform: translateX(50%);
    }
    &__bottomTitle {
      float: left;
      transform: translateX(-50%);
    }

    @include tablet-landscape {
      &__topTitle {
        transform: translateX(0);
      }
      &__bottomTitle {
        transform: translateX(0);
      }
    }
  }
}
</style>
