<script setup>
import { sitecoreComponentProps } from '@/utils/constants';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import api from '@/api';
let interval = null;
const props = defineProps({
  fields: {
    type: Object
  },
  ...sitecoreComponentProps
});
const isLive = ref(props.fields?.turnOnLiveShow.value);
const checkStatus = async () => {
  const [res] = await api.web.item({
    id: props.rendering.dataSource
  });
  isLive.value = res?.turnOnLiveShow ?? false;
  if (res?.turnOnLiveShow) {
    clearInterval(interval);
  }
};
onMounted(() => {
  const { turnOnLiveShow } = props.fields ?? {};
  if (!turnOnLiveShow?.value) {
    interval = setInterval(checkStatus, 10000);
  }
});
onBeforeUnmount(() => {
  if (interval) {
    clearInterval(interval);
  }
});
</script>

<template>
  <overall-settings v-bind="$props">
    <div class="c-iframe-content">
      <icon class="c-iframe-content__logo" :field="fields.logo" />
      <div class="c-iframe-content__main" v-aspect-ratio="'10_6'">
        <iframe class="c-iframe-content__iframe" :src="fields.src.value" allowtransparency allowfullscreen allow="autoplay; encrypted-media" v-if="isLive" />
        <adaptive-image :field="fields.image" v-else />
      </div>
    </div>
  </overall-settings>
</template>

<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
.c-iframe-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  background-color: $black;
  color: $white;
  &__logo {
    color: $yellow;
    &.e-icon {
      display: flex;
    }
    align-items: center;
    height: mobile-header-height();
  }
  &__main {
    width: 100%;
    aspect-ratio: 10/6;
    .e-background-image {
      height: 100%;
    }
  }
  &__iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  @include tablet-landscape {
    height: 100vh;
    &__logo {
      height: tablet-header-height();
      flex-shrink: 0;
    }
    &__main {
      aspect-ratio: auto;
      flex-grow: 1;
    }
  }
  @include desktop {
    &__logo {
      height: desktop-header-height();
    }
  }
}
</style>
