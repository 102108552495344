<template>
  <div class="c-lotus-center" v-if="fields">
    <div class="c-lotus-center__content">
      <div class="c-lotus-center__map" ref="mapNode"></div>
      <div class="c-lotus-center__details" v-if="showInfo">
        <div class="c-lotus-center__details-info">
          <div class="c-lotus-center__details-info-content">
            <div class="c-lotus-center__details-info-close" @click="showInfo = false">
              <SiteButton class="c-lotus-center__details-info-close-btn" v-bind="fields.closeDetailsButton" />
            </div>
            <div class="c-lotus-center__details-info-title">{{ showStore.storeName }}</div>
            <div class="c-lotus-center__details-info-type">{{ showStore.districtName }}</div>
            <div class="c-lotus-center__details-info-dec">{{ showStore.addressDetail }}</div>
            <div class="c-lotus-center__details-info-phones">
              <div class="c-lotus-center__details-info-phones-sales" v-if="showStore.extra.salesLine">
                <div class="c-lotus-center__details-info-label">
                  <JssText :field="fields.salesTitle" />
                </div>
                <div class="c-lotus-center__details-info-number">
                  <a :href="`tel:${showStore.salesLine}`" ref="telPhone">
                    <Icon :svg="fields.phoneIcon?.value.svgCode" class="c-lotus-center__details-info-number-icon" />
                    <span>{{ showStore.extra.salesLine }}</span>
                  </a>
                </div>
              </div>
              <div class="c-lotus-center__details-info-phones-service" v-if="showStore.serviceCall">
                <div class="c-lotus-center__details-info-label">
                  <JssText :field="fields.serviceTitle" />
                </div>
                <div class="c-lotus-center__details-info-number">
                  <a :href="`tel:${showStore.serviceCall}`" ref="telPhone">
                    <Icon :svg="fields.phoneIcon?.value.svgCode" class="c-lotus-center__details-info-number-icon" />
                    <span>{{ showStore.serviceCall }}</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="c-lotus-center__details-info-btns">
              <SiteButton class="c-lotus-center__details-info-btn" v-if="showStore.extra?.officialWebsiteUrl" v-bind="fields.websiteButton" @click="jump(showStore.extra?.officialWebsiteUrl)" />
              <SiteButton class="c-lotus-center__details-info-btn" v-bind="fields.contactButton" />
            </div>
          </div>
          <div class="c-lotus-center__details-list">
            <div class="c-lotus-center__details-list-item" v-if="salesList.length > 0">
              <div class="c-lotus-center__details-list-title">
                <JssText :field="fields.salesTitle" />
              </div>
              <ul>
                <li v-for="item in salesList" :key="item.dayTime">
                  <div class="c-lotus-center__details-list-day">{{ item.dayTime }}</div>
                  <div class="c-lotus-center__details-list-time">{{ item.time }}</div>
                </li>
              </ul>
            </div>
            <div class="c-lotus-center__details-list-item" v-if="serviceList.length > 0">
              <div class="c-lotus-center__details-list-title">
                <JssText :field="fields.serviceTitle" />
              </div>
              <ul>
                <li v-for="item in serviceList" :key="item.dayTime">
                  <div class="c-lotus-center__details-list-day">{{ item.dayTime }}</div>
                  <div class="c-lotus-center__details-list-time">{{ item.time }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="c-lotus-center__details-map-content">
          <div class="c-lotus-center__details-map" ref="miniMapNode"></div>
        </div>
      </div>
      <ContentLoading class="layout-loading" :loading="loading" />
      <div class="c-lotus-center__search-mobile-open-icon" v-if="mapFlag && !showSearch && !largeThanTablet()" ref="showSearchMobileNode" @click="toggleSearch">
        <Icon :svg="fields.searchIcon?.value.svgCode" class="c-lotus-center__search-mobile-icon" size="large" />
      </div>
      <div class="c-lotus-center__search-mobile" :class="{ open: showSearch }" v-if="!largeThanTablet()" ref="searchMobileNode">
        <div class="c-lotus-center__search-mobile-close-icon" @click="toggleSearch">
          <Icon :svg="fields.closeSearchIcon?.value.svgCode" class="c-lotus-center__search-mobile-icon" />
        </div>
        <div class="c-lotus-center__search-mobile-title" ref="mobileTitleEl">
          <JssText :field="fields.searchTitle" />
        </div>
        <div class="e-form-input c-lotus-center__search-mobile-input-group" :class="[...inputClasses]">
          <label class="e-form-input__label" for="search-mobile-input" v-if="fields.searchInput?.fields.label">{{ fields.searchInput.fields.label.value }}</label>
          <div class="e-form-input__wrapper">
            <div class="e-form-input__main" ref="searchInputWrapper">
              <input
                id="search-mobile-input"
                class="e-form-input__input c-lotus-center__search-mobile-input"
                :class="[{ open: showSearch }, { reverse: reverseSearchInput }]"
                :placeholder="fields.searchInput?.fields.placeholder.value"
                type="text"
                autocomplete="off"
                ref="searchInput"
              />
            </div>
            <div class="e-form-input__icon" v-if="fields.searchInput?.fields.icon">
              <Icon :svg="fields.searchInput?.fields.icon?.value.svgCode" @click="changeLocation" />
            </div>
          </div>
        </div>
        <div class="c-lotus-center__search-mobile-btns">
          <SiteButton class="c-lotus-center__search-mobile-center-btn" v-bind="centerButton" @click="toggleCenter" />
          <SiteButton class="c-lotus-center__search-mobile-service-btn" v-bind="serviceButton" @click="toggleService" />
        </div>
      </div>
      <div class="c-lotus-center__search-pc" :class="{ open: showSearch }" v-else ref="searchPcNode">
        <div class="c-lotus-center__search-pc-button" @click="toggleSearch" v-if="mapFlag">
          <Icon :svg="showSearch ? fields.closeSearchIcon.value.svgCode : fields.searchIcon?.value.svgCode" class="c-lotus-center__search-pc-icon" />
        </div>
        <div class="c-lotus-center__search-pc-title" ref="pcTitleEl">
          <JssText :field="fields.searchTitle" />
        </div>
        <div class="e-form-input c-lotus-center__search-pc-input-group" :class="[...inputClasses]">
          <label class="e-form-input__label" for="search-pc-input" v-if="fields.searchInput?.fields.label">{{ fields.searchInput?.fields.label?.value }}</label>
          <div class="e-form-input__wrapper">
            <div class="e-form-input__main" ref="searchInputWrapper">
              <input
                id="search-pc-input"
                class="e-form-input__input c-lotus-center__search-pc-input"
                :class="[{ open: showSearch }, { reverse: reverseSearchInput }]"
                :placeholder="fields.searchInput?.fields.placeholder.value"
                type="text"
                autocomplete="off"
                ref="searchInput"
              />
            </div>
            <div class="e-form-input__icon" v-if="fields.searchInput?.fields.icon">
              <Icon :svg="fields.searchInput?.fields.icon?.value.svgCode" size="small" @click="changeLocation" />
            </div>
          </div>
        </div>
        <div class="c-lotus-center__search-pc-btns">
          <SiteButton class="c-lotus-center__search-pc-center-btn" v-bind="centerButton" @click="toggleCenter" />
          <SiteButton class="c-lotus-center__search-pc-service-btn" v-bind="serviceButton" @click="toggleService" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRefs, onMounted, nextTick, reactive, computed, inject } from 'vue';
import { getGlobalConfigs, loadGMap, getCurrentAlpha2Code } from '@/utils/site-utils';
import { MAP_BLACK_STYLE } from '@/utils/constants';
import { largeThanTablet } from '@/utils/dom-utils';
import { isNullOrEmpty } from '@/utils/obj-utils';
import { equalString } from '@/utils/string-utils';
import { gtmPush } from '@/utils/gtm-utils';
import api from '@/api/index';
import { debounce } from 'lodash';
import { useI18n } from 'vue-i18n';
export default {
  name: 'LotusCenter',
  props: {
    page: {
      type: Object
    },
    fields: {
      type: Object
    }
  },
  setup(props) {
    if (!props.fields) return;
    const toast = inject('toast');
    const { t: i18n } = useI18n();
    const state = reactive({
      /**@type {HTMLElement}*/
      mapNode: null,
      /**@type {HTMLElement}*/
      miniMapNode: null,
      /**@type {HTMLElement}*/
      showSearchMobileNode: null,
      /**@type {HTMLElement}*/
      searchMobileNode: null,
      /**@type {HTMLElement}*/
      searchPcNode: null,
      /**@type {HTMLElement}*/
      searchInput: null,
      searchInputWrapper: null,
      /**@type {HTMLElement}*/
      mobileTitleEl: null,
      /**@type {HTMLElement}*/
      pcTitleEl: null,
      showInfo: false,
      showSearch: false,
      showCenter: true,
      showService: true,
      mapFlag: false,
      showStore: null,
      salesList: [],
      serviceList: [],
      loading: false
    });
    let maps = null;
    let map = null;
    let miniMap = null;
    let searchBox = null;
    let zoom = largeThanTablet() ? 6 : 12;
    const maxZoom = 16;
    const minZoom = 3;
    let storeList = [];
    let markers = [];
    const computes = {
      centerButton: computed(() => (state.showCenter ? props.fields.centerButton : props.fields.closedCenterButton)),
      serviceButton: computed(() => (state.showService ? props.fields.serviceButton : props.fields.closedServiceButton)),
      reverseSearchInput: computed(() => props.fields.searchInput?.fields.reverse.value),
      inputClasses: computed(() => [
        {
          'has-icon': !!props.fields.searchInput?.fields.icon,
          reverse: computes.reverseSearchInput.value
        }
      ])
    };
    const methods = {
      largeThanTablet,
      getCoordinatesPix: (str) => {
        const pixs = str.split(',');
        return [parseFloat(pixs[0]), parseFloat(pixs[1])];
      },
      addGoogleMarkup: (i) => {
        let temp = {
          '@context': 'https://schema.org',
          '@type': 'AutomotiveBusiness',
          name: i.storeName,
          image: i.storeImgDtoList[0]?.externalUrl,
          '@id': window.location.href,
          url: i.extra?.officialWebsiteUrl,
          telephone: i.serviceCall,
          address: { '@type': 'PostalAddress', streetAddress: i.addressDetail, addressLocality: i.countryName, postalCode: i.extra?.storeZipCode, addressCountry: i.country2Code },
          geo: {
            '@type': 'GeoCoordinates',
            latitude: i.latitude,
            longitude: i.longitude
          },
          openingHoursSpecification: [
            {
              '@type': 'OpeningHoursSpecification',
              dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
              opens: i.workStartTime,
              closes: i.workEndTime
            }
          ]
        };
        const script = document.createElement('script');
        script.setAttribute('type', 'application/ld+json');
        script.textContent = JSON.stringify(temp);
        document.head.appendChild(script);
      },
      initComponent: async (_maps) => {
        if (state.mapNode) {
          maps = _maps;
          // if (navigator.geolocation) {
          //   navigator.geolocation.getCurrentPosition(
          //     (e) => {
          //       methods
          //         .initMap(e)
          //         .then(() => {
          //           state.mapFlag = true;
          //         })
          //         .catch();
          //     },
          //     (ex) => {
          //       methods
          //         .initMap()
          //         .then(() => {
          //           state.mapFlag = true;
          //         })
          //         .catch();
          //     }
          //   );
          // } else {
          methods
            .initMap()
            .then(() => {
              state.mapFlag = true;
            })
            .catch();
          // }
        }
      },
      initWorkTime: (res) => {
        state.salesList = [];
        state.serviceList = [];
        if (res.businessHoursSalesObj && res.businessHoursSalesObj.length) {
          res.businessHoursSalesObj.forEach((b) => {
            let salesWorkTime = '';
            if (b.workStartTime.slice(0, 5) === '00:00' && b.workEndTime.slice(0, 5) === '00:00') {
              salesWorkTime = i18n('Closed');
            } else {
              salesWorkTime = b.workStartTime.slice(0, 5) + ' - ' + b.workEndTime.slice(0, 5);
            }
            let dayTime = i18n('Mon');
            if (b.week == 2) {
              dayTime = i18n('Tue');
            } else if (b.week == 3) {
              dayTime = i18n('Wed');
            } else if (b.week == 4) {
              dayTime = i18n('Thu');
            } else if (b.week == 5) {
              dayTime = i18n('Fri');
            } else if (b.week == 6) {
              dayTime = i18n('Sat');
            } else if (b.week == 7) {
              dayTime = i18n('Sun');
            }
            state.salesList.push({
              dayTime: dayTime,
              time: salesWorkTime
            });
          });
        }
        if (res.businessHoursServiceObj && res.businessHoursServiceObj.length) {
          res.businessHoursServiceObj.forEach((b) => {
            let serviceWorkTime = '';
            if (b.workStartTime.slice(0, 5) === '00:00' && b.workEndTime.slice(0, 5) === '00:00') {
              serviceWorkTime = i18n('Closed');
            } else {
              serviceWorkTime = b.workStartTime.slice(0, 5) + ' - ' + b.workEndTime.slice(0, 5);
            }
            let dayTime = i18n('Mon');
            if (b.week == 2) {
              dayTime = i18n('Tue');
            } else if (b.week == 3) {
              dayTime = i18n('Wed');
            } else if (b.week == 4) {
              dayTime = i18n('Thu');
            } else if (b.week == 5) {
              dayTime = i18n('Fri');
            } else if (b.week == 6) {
              dayTime = i18n('Sat');
            } else if (b.week == 7) {
              dayTime = i18n('Sun');
            }
            state.serviceList.push({
              dayTime: dayTime,
              time: serviceWorkTime
            });
          });
        }
      },
      initMap: async () => {
        const currentAlpha2Code = getCurrentAlpha2Code();
        const coordinates = isNullOrEmpty(props.fields.center?.value) ? [47.37, 6.1] : methods.getCoordinatesPix(props.fields.center.value);
        const localStores = storeList.filter((s) => equalString(s.country2Code, currentAlpha2Code));
        const lotusCentre = window.btoa(`${props.fields.centreIcon.value.svgCode}`);
        const lotusWorks = window.btoa(`${props.fields.worksIcon.value.svgCode}`);
        let mapOptions = {
          mapTypeId: maps.MapTypeId.ROADMAP,
          mapTypeControl: false,
          fullscreenControl: false,
          zoom,
          maxZoom,
          minZoom,
          styles: MAP_BLACK_STYLE,
          center: { lat: coordinates[0], lng: coordinates[1] }
        };
        if (localStores && localStores.length === 1) {
          const localStore = localStores[0];
          mapOptions.center = { lat: Number(localStore.latitude), lng: Number(localStore.longitude) };
          const [res, ex] = await api.store.detail({ storeCode: localStore.storeCode });
          if (ex) {
            await toast.showEx(ex);
          } else {
            state.showStore = res;
            methods.initWorkTime(res);
            state.showInfo = true;
            nextTick(() => {
              miniMap = new maps.Map(state.miniMapNode, mapOptions);
              miniMap.setCenter(new maps.LatLng(Number(localStore.latitude), Number(localStore.longitude)));
              miniMap.setZoom(18);
              let icon = '';
              if (state.showStore.storeType.indexOf('1') >= 0) {
                icon = `data:image/svg+xml;base64,${lotusCentre}`;
              } else {
                icon = `data:image/svg+xml;base64,${lotusWorks}`;
              }
              new maps.Marker({
                position: new maps.LatLng(Number(localStore.latitude), Number(localStore.longitude)),
                icon: icon,
                map: miniMap
              });
            });
          }
        }
        map = new maps.Map(state.mapNode, mapOptions);
        let latlngList = [];
        storeList.forEach((t) => {
          latlngList.push(new maps.LatLng(t.latitude, t.longitude));
          let icon = '';
          if (t.storeType.indexOf('1') >= 0) {
            icon = `data:image/svg+xml;base64,${lotusCentre}`;
          } else {
            icon = `data:image/svg+xml;base64,${lotusWorks}`;
          }
          const marker = new maps.Marker({
            position: new maps.LatLng(t.latitude, t.longitude),
            icon: icon,
            map: map,
            code: t.storeCode,
            type: t.storeType,
            id: t.id
          });
          marker.addListener('click', async () => {
            state.loading = true;
            state.showInfo = false;
            const [res, ex] = await api.store.detail({ storeCode: marker.code });
            if (ex) {
              await toast.showEx(ex);
            } else {
              state.showStore = res;
              methods.initWorkTime(res);
              state.showInfo = true;
              state.loading = false;
              nextTick(() => {
                miniMap = new maps.Map(state.miniMapNode, mapOptions);
                miniMap.setCenter(marker.position);
                miniMap.setZoom(18);
                let icon = '';
                if (state.showStore.storeType.indexOf('1') >= 0) {
                  icon = `data:image/svg+xml;base64,${lotusCentre}`;
                } else {
                  icon = `data:image/svg+xml;base64,${lotusWorks}`;
                }
                new maps.Marker({
                  position: marker.position,
                  icon: icon,
                  map: miniMap
                });
              });
            }
          });
          markers.push(marker);
        });
        let bounds = new maps.LatLngBounds();
        latlngList.forEach((l) => {
          bounds.extend(l);
        });
        // if (e?.coords) {
        //   center = new maps.LatLng(e.coords.latitude, e.coords.longitude);
        //   const data = {
        //     event: 'find_location',
        //     dealer_search_type: 'service',
        //     dealer_search_method: 'own_location',
        //     dealer_search_input_location: center
        //   };
        //   gtmPush(data);
        //   // } else {
        //   //   center = bounds.getCenter();
        //   map.setCenter(center);
        //   map.setZoom(8);
        // }

        let searchUI, showIconUI;
        if (largeThanTablet()) {
          showIconUI = state.showSearchMobileNode;
          map.controls[maps.ControlPosition.TOP_CENTER].push(showIconUI);
          searchUI = state.searchPcNode;
          map.controls[maps.ControlPosition.TOP_CENTER].push(searchUI);
          state.showSearch = true;

          const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
          const alpha2Code = getCurrentAlpha2Code();
          if (alpha2Code) {
            const countryName = regionNames.of(alpha2Code);
            const geocoder = new maps.Geocoder();
            geocoder.geocode({ address: countryName }, async (locations, status) => {
              if (status == maps.GeocoderStatus.OK) {
                map.fitBounds(locations[0].geometry.viewport);
              }
            });
          }
        } else {
          searchUI = state.searchMobileNode;
          map.controls[maps.ControlPosition.TOP_CENTER].push(searchUI);
        }

        searchBox = new maps.places.SearchBox(state.searchInput);
        map.addListener('drag', () => {
          const bounds = map.getBounds();
          const center = map.getCenter();
          const sLat = bounds.getSouthWest().lat();
          const nLat = bounds.getNorthEast().lat();
          const cLat = center.lat();
          const cLng = center.lng();
          if (sLat < -85) {
            map.setCenter(new maps.LatLng(cLat + (sLat + 85) * -1, cLng));
          }
          if (nLat > 85) {
            map.setCenter(new maps.LatLng(cLat - nLat + 85, cLng));
          }
        });
        map.addListener('bounds_changed', () => {
          searchBox.setBounds(map.getBounds());
        });
        searchBox.addListener('places_changed', () => {
          const places = searchBox.getPlaces();
          if (places.length == 0) {
            return;
          }
          const bounds = new maps.LatLngBounds();
          places.forEach((place) => {
            if (!place.geometry || !place.geometry.location) {
              console.log('Returned place contains no geometry');
              return;
            }
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          const data = {
            event: 'find_location',
            dealer_search_type: 'service',
            dealer_search_method: 'input_location',
            dealer_search_input_location: state.searchInput.value
          };
          gtmPush(data);
          map.fitBounds(bounds);
        });
      },
      changeMarkers: () => {
        markers.forEach((m) => {
          if (m.type.indexOf('1') >= 0) {
            if (!state.showCenter) {
              m.setMap(null);
            } else {
              m.setMap(map);
            }
          } else {
            if (!state.showService) {
              m.setMap(null);
            } else {
              m.setMap(map);
            }
          }
        });
      },
      toggleSearch: () => {
        state.showInfo = false;
        state.showSearch = !state.showSearch;
      },
      toggleCenter: () => {
        state.showCenter = !state.showCenter;
        methods.changeMarkers();
      },
      toggleService: () => {
        state.showService = !state.showService;
        methods.changeMarkers();
      },
      changeLocation: debounce(() => {
        navigator.geolocation.getCurrentPosition((e) => {
          map.setCenter(new maps.LatLng(e.coords.latitude, e.coords.longitude));
        });
      }, 20),
      jump: (url) => {
        window.open(url);
      }
    };
    onMounted(async () => {
      const [res, ex] = await api.store.query({ pageNum: '1', pageSize: '999999' });
      if (ex) {
        await toast.showEx(ex);
      } else {
        const [hiddenStores] = getGlobalConfigs(props.page, 'hiddenStores');
        const hiddenStoreCodes = (hiddenStores?.value ?? '').split(';');
        storeList = (res ?? []).filter((x) => !hiddenStoreCodes.some((y) => equalString(y, x.storeCode)));
        storeList.forEach((t) => {
          methods.addGoogleMarkup(t);
        });
        await nextTick(() => {
          loadGMap(props.page).then((maps) => {
            maps && methods.initComponent(maps).catch();
          });
        });
      }
    });
    return {
      ...toRefs(state),
      ...computes,
      ...methods
    };
  }
};
</script>
<style lang="scss">
@import '../styles/variable';
@import '../styles/function';
@import '../styles/mixin';
@import '../styles/rte/color.scss';
.c-lotus-center {
  height: 100vh;
  color: #fff;
  &__content {
    position: relative;
    height: 100%;
  }
  &__map {
    height: 100%;
  }
  &__details {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    &-info {
      height: 70%;
      overflow-y: scroll;
      background-color: #222;
      &-content {
        padding: 78px $space-32;
      }
      &-close {
        text-align: end;
        color: #ffff00;
        .e-site-button {
          padding: 0;
          &__text {
            padding: 0;
          }
        }
        .e-icon {
          padding-right: 0 !important;
        }
      }
      &-title {
        font-size: 56px;
        line-height: 1.1;
      }
      &-type {
        font-size: 16px;
        line-height: 20px;
        color: #fef151;
      }
      &-dec {
        font-size: 16px;
        line-height: 20px;
      }
      &-label {
        font-size: 16px;
        line-height: 20px;
      }
      &-phones {
        display: flex;
        flex-direction: column;
        margin-top: $space-40;
        > div + div {
          margin-top: $space-24;
        }
      }
      &-number {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 300;
        font-size: 24px;
        line-height: 32px;
        &-icon {
          padding-right: $space-12;
        }
      }
      &-btns {
        margin-top: $space-24;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $space-24;
        .e-site-button {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
    }
    &-list {
      background-color: $grey-neutral;
      padding: $space-10 $space-12;
      display: flex;
      flex-direction: column;
      gap: $space-24;
      &-title {
        padding-left: $space-16;
      }
      > div {
        padding: 0 $space-4;
      }
      li {
        padding: $space-4 $space-16;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &:nth-child(even) {
          background-color: $grey-medium;
        }
        .c-lotus-center__details-list-day {
          flex: 1;
        }
        .c-lotus-center__details-list-time {
          flex: 2;
          text-align: end;
        }
      }
    }
    &-map {
      height: 100%;
      &-content {
        height: 30%;
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: $space-20;
        background-color: #393939;
      }
    }
  }
  &__search-mobile {
    width: 80%;
    position: absolute;
    top: 80px !important;
    left: 0 !important;
    right: 0;
    background-color: #222;
    padding: 23px $space-32 $space-40;
    margin: 0 auto;
    display: none;
    &-open-icon {
      position: absolute;
      top: 25% !important;
      left: 0 !important;
      width: 40px;
      height: 40px;
      background-color: #ffff00;
      color: $black;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    &-close-icon {
      position: absolute;
      top: -40px;
      right: 0;
      width: 40px;
      height: 40px;
      background-color: #ffff00;
      color: $black;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    &-icon {
      width: 20px;
      height: 20px;
      > svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
    &.open {
      display: block;
    }
    &-input {
      height: 100%;
      padding: $space-4 $space-12;
      display: none;
      &.open {
        display: block;
      }
      &-group {
        margin-top: $space-24;
        .e-form-input__wrapper {
          height: 40px;
          border: 1px solid;
          padding: 0 $space-40;
        }
        .e-form-input__icon {
          min-width: 40px;
        }
        .e-icon {
          padding: 0 !important;
          svg {
            height: 20px !important;
            width: 20px !important;
          }
        }
      }
      &.reverse {
        left: calc(10% + 72px) !important;
      }
    }
    &-button {
      display: inline-block;
      position: absolute;
      top: 0;
      right: -40px;
      width: 40px;
      height: 40px;
      background-color: #ffff00;
      color: $black;
      cursor: pointer;
    }
    &-title {
      font-weight: 300;
      font-size: 24px;
      line-height: 32px;
    }
    &-btns {
      @include h9;
      margin-top: $space-40;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: $space-40;
      .e-site-button {
        height: 44px;
        width: 100%;
        padding: 0 $space-56 !important;
        .e-site-button__text {
          padding: 0 $space-4 !important;
        }
        .e-icon {
          padding: 0 !important;
          svg {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
    .e-form-input__label {
      font-size: 14px;
      line-height: 1.3;
      background-color: #222;
    }
  }

  @include tablet {
    &__details {
      height: 100%;
      width: grid-width(11);
      min-width: 500px;
      top: 0;
      right: 0;
      left: auto;
      z-index: 11;
      overflow: hidden;
      &-info {
        height: 60%;
        &-content {
          padding: $space-20 grid-width(1);
        }
        &-btns {
          align-items: flex-start;
          .e-site-button {
            width: auto;
            min-width: 190px;
          }
        }
      }
      &-list {
        flex-direction: row;
        > div {
          flex: 1;
        }
      }
      &-map {
        &-content {
          height: 40%;
          padding: $space-24;
        }
      }
    }
    &__search-pc {
      min-width: 480px;
      position: absolute;
      top: 25% !important;
      left: 0 !important;
      background-color: #222;
      padding: $space-40 $space-32;
      transform: translateX(-100%);
      transition: all 0.2s linear;
      &.open {
        transform: translateX(0);
      }
      .e-form-input {
        padding-bottom: 0;
      }
      &-input {
        height: 100%;
        padding: $space-4 $space-12;
        transform: translateX(-150%);
        transition: all 0.2s linear;
        &.open {
          transform: translateX(0);
        }
        &-group {
          margin-top: $space-40;
          .e-form-input__wrapper {
            height: 40px;
            border: 1px solid;
            padding: 0 $space-40;
          }
          .e-form-input__icon {
            min-width: 40px;
            cursor: pointer;
            .e-icon {
              svg {
                width: 20px;
              }
            }
          }
        }
        &.reverse {
          left: calc(#{grid-width(1)} + 40px) !important;
        }
      }
      &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: -40px;
        width: 40px;
        height: 40px;
        background-color: #ffff00;
        color: $black;
        cursor: pointer;
      }
      &-title {
        font-weight: 300;
        font-size: 24px;
        line-height: 32px;
      }
      &-btns {
        display: flex;
        flex-direction: row;
        margin-top: $space-40;
        gap: $space-10;
        .e-site-button {
          height: 44px;
          .e-site-button__text {
            padding: 0 $space-4 !important;
          }
          .e-icon {
            padding: 0 !important;
            margin-left: 0;
            svg {
              height: 30px;
            }
          }
        }
      }
      .e-form-input__label {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }
  @include desktop-large {
    &__details {
      &-info {
        &-phones {
          flex-direction: row;
          margin-top: $space-48;
          > div {
            flex: 1;
          }
          > div + div {
            margin-top: 0;
          }
        }
      }
    }
  }
}
.pac-container {
  border: 0 !important;
  &:after {
    display: none;
  }
}
.pac-item {
  color: #fff;
  padding: $space-10 $space-16;
  background-color: #4f4f4f;
  border-top: none;
  &:hover {
    background-color: #000;
  }
  .pac-icon {
    display: none;
  }
  .pac-item-query {
    color: #fff;
  }
}
.gm-style-cc {
  display: none;
}

.gm-bundled-control-on-bottom {
  bottom: 220px !important;
}
</style>
